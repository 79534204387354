@import url('../../Styles/helper.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-richtexteditor/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-calendars/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-gantt/styles/material.css';
@import '@syncfusion/ej2-grids/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-layouts/styles/material.css';
@import '@syncfusion/ej2-lists/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-richtexteditor/styles/material.css';
@import '@syncfusion/ej2-treegrid/styles/material.css';

:root {
  --clr-grey: #bdbdbd;
  --clr-txt: #232125;
  --clr-primary: #711fff;
  --clr-cyan: #47d1fc;
}
.custom-class {
  height: 70%;
}

.tiptap::before {
  height: 100%;
  background-color: #000;
}
.ProseMirror {
  border-top: none !important;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  max-height: 57.8vh;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  outline: none !important;
  padding: 1rem 1rem;
}

.super-col-first {
  max-width: 30%;
}
.super-col-second {
  max-width: 100%;
}

.custom-col-first {
  max-width: 13.2%;
}
.custom-col-second {
  max-width: 86.8%;
}
@media only screen and (max-width: 1560px) {
  .custom-col-first {
    max-width: 15.8%;
  }
  .custom-col-second {
    max-width: 84.2%;
  }
}

@media only screen and (max-width: 1200px) {
  .custom-col-first {
    max-width: 0%;
  }
  .custom-col-second {
    max-width: 100%;
  }
}

.inner-app {
  height: 100vh;
  overflow: hidden !important ;
  background-color: var(--grey-300);
}

/* Hide scrollbar in WebKit (Chrome, Safari) */
.inner-app::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar in Firefox */
.inner-app {
  overflow: hidden;
  scrollbar-width: thin;
}

/* Hide scrollbar in Edge */
.inner-app {
  -ms-overflow-style: none !important  ;
}

/* Hide scrollbar in Edge (when not focused) */
.inner-app:hover {
  -ms-overflow-style: scrollbar;
}

ul {
  list-style: none !important;
}

.sidenav-col {
  max-width: 252px;
  width: 100%;
  height: 100%;
}

.page-col {
  position: relative;
}

.row {
  height: 100vh;
}

.app-body {
  overflow: hidden !important;
}

.dd-col-box-ag-fs-strt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100% !important;
}

.MuiAutocomplete-popper {
  z-index: 999999999 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.dFlex {
  display: flex;
  align-items: center;
  justify-content: start;
}

.m1 {
  margin: 1.5rem 0;
}

.jCntr {
  justify-content: flex-start;
  align-items: center;
}

.spaceAroundTxt {
  margin: 0 0.5rem;
}

.fsMedium {
  font-size: 1.7rem;
}
code {
  color: #f964ae;
  font-size: 0.875em;

  background: aliceblue;
  padding: 0.2rem 0.5rem;
  border-radius: 2px;
}
.people-mention {
  color: #7f42e0;
  border-radius: 0.3rem;
  padding: 0.1rem 0.3rem;
}
.deliverable-mention {
  color: #7f42e0;
  font-weight: 700;
  background-color: aliceblue;
  border-radius: 0.3rem;
  padding: 0.1rem 0.3rem;
}

.coljCntr {
  justify-content: center;
  align-items: center;
}

.icon {
  width: 2.5rem;
  height: 2rem;
  margin: 0 0.5rem;
}

.exceptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 4rem;
  color: #f00;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}

.btn {
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 7px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #711fff;
  align-self: flex-start;
  color: #fff;
  margin: 1rem 0;
}

.btn:focus {
  background-color: #711fff;
  outline: none;
  color: #fff;
}

.btn:hover {
  background-color: #5a0bdf;
  color: #fff;
}

.invalid {
  border: 1px solid #f00;
}

.error {
  color: #f00;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  align-self: flex-start;
}

.succ {
  color: #47d1fc;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  align-self: flex-start;
}

.error::after {
  content: '*';
}

.AxisCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 200px;
  transition: 0.5s;
  padding-bottom: 20px;
  background-color: #f5f5f5;
}

.Intro {
  justify-self: flex-start;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  padding: 1rem 1rem;
}

.Intro h1 {
  font-size: 1.5rem;
  text-align: left;
}

.navDesing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-items: flex-start;
  margin: auto;
}

.NavItemSpan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.645rem 0.85rem;
  white-space: unset;
}

.NavSpani {
  margin-left: 0.85rem;
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
}

.navImg {
  padding: 0 0.5rem;
  transition: all 0.3s ease-in-out;
}

.navImg:hover {
  border-left: 5px solid #711fff;
  transition: all 0.3s ease-in-out;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #5a0bdf;
  width: 100%;
  z-index: 1;
}

.supportTabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 200px;
}

.tabs {
  text-align: center;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  align-self: flex-start;
  margin: 0px 15px;
}

.tabs:hover {
  background-color: #310085;
  padding: 18px 27px;
}

.user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  align-self: flex-end;
  justify-self: flex-end;
  margin-top: auto;
  margin-bottom: 3px;
}

.profile-circle {
  width: 30px;
  height: 30px;
  background-color: #828282;
  border-radius: 50%;
  margin-right: 25px;
}

.user-name {
  margin-top: 6px;
}

/***** INNER APP *******/
.main-layout {
  position: relative;
}

.row {
  display: flex;
}

.module-col {
  width: 100%;
}

.home-col {
  width: 100%;
}

/* OPPORTUNITY START */

.css-1g1inyy-MuiButtonBase-root-MuiTab-root {
  text-transform: none !important;
}

#embedVideo {
  max-width: none !important;
}

@media screen and (max-width: 900px) {
  .illustraton,
  .onBording,
  .RegisterIllustration {
    display: none;
  }
}

@media screen and (max-width: 1366px) {
  .illustraton,
  .RegisterIllustration {
    background-size: 380px;
  }
}
/* this is custom scroll bar */

.customScrollBar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  cursor: pointer;
  margin-right: 10px;
}

/* Track */
.customScrollBar::-webkit-scrollbar-track {
  background-color: white;
  cursor: pointer;
}

/* Handle */
.customScrollBar::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
  width: 5px;
  height: 5px;
}

/* Handle on hover */
.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

/* this is acountbilling menu bar css */

.fsIcon {
  font-size: 10px !important;
  height: 100%;
}

.center {
  text-align: center;
}

@media screen and (min-width: 900px) {
  .fsIcon {
    font-size: 13px !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollShow::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ScrollShow {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.fade-in-text {
  font-family: Arial;
  font-size: 60px;
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
