p {
  margin-bottom: 0 !important;
}


.login-page {
  display: flex;
  align-items: center;

}


.link {
  color: #711fff;
  text-align: left;
  margin-left: 0.5rem;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}

.link2 {
  color: #711fff;
  text-align: left;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}

.illustraton {
  height: 100vh;
  background-color: #47d1fc;

}

.login-form,
.registration-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  height: 100vh;
  padding: 16px;
  gap: 0.4rem;
  gap: 0 !important;
  justify-self: flex-end !important;
  flex-basis: auto;
  width: calc(100% - 60%);
}

.form-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.2rem;
  place-self: flex-start;
}


form label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
}

form label input,
.form-input {
  padding: 1rem;
  width: 100%;
  border-radius: 5px;
  margin: 0.90rem 0;
  border: 1px solid #bdbdbd;
  color: #232125;
}

@media (width <= 900px) {
  form label input,
  .form-input {
    padding: 0.5rem;
    width: 100%;
    border-radius: 5px;
    margin: 0.90rem 0;
    border: 1px solid #bdbdbd;
    color: #232125;
  }
}


form label input,
.form-input:active {
  border: 2px solid #711fff;
  color: #232125;
  font-weight: 600;
}

/* stylelint-disable-next-line selector-class-pattern */
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.0094em;
  color: rgb(0 0 0 / 87%);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: static !important;
  border-radius: 4px;
}

form label input{
  outline: none;
}

.form label input{
  color: #bdbdbd;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

form label input:focus,
.form-input:focus {
  border: 2px solid #711fff;
  color: #232125;
  font-weight: 600;
}




.form-input:focus {
  outline: none;
}


.form-input::placeholder {
  color: #bdbdbd;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}


.links {
  place-self: center !important;
  width: 400px;
  margin: 2rem 0;
}

.links p {
  margin: 0.4rem 0;
  text-align: left;
}

.col-txt {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 1rem 0.5rem;
  width: 400px;
  gap: 0.4rem;
  margin: 2rem 1.5rem 2rem 0;

}

.col-txt p {
  margin: 0.3rem 0;
}

.box-txt-right {
  left: 60%;
}

.box-txt {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 1rem 0.5rem;
  width: 420px;
  margin: 0 2rem;
}

.box-txt img {
  margin: 2rem 0;
}

.box-txt p {
  margin: 0.1rem 0;
}

.sub-heading {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
}

@media screen and (width <= 899px) {
  .sub-heading {
    margin-top: 3rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 1.8rem;
  }
}


.fstxt {
  color: #232125;
  letter-spacing: -0.3px;
  text-align: left;
  font-size: 1rem;
  font-family: 'Open sans', sans-serif;
  font-weight: 600;
}

.register-illustration {
  height: 100vh;
  background-color: #47d1fc;
}

.on-boarding {
  background-image: url('../Assets/registr.png');
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #47d1fc;
  width: 70%;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
}