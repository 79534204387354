ul[data-type='taskList'] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  .mention {
    background-color: #990a0a;
    color: #333333;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 10px;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }

    ul li,
    ol li {
      display: list-item;
    }
  }
}
.space {
  text-indent: 20px;
  background-color: red;
}

.rich-text-editor-wrapper {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
}

.ProseMirror {
  /* white-space: normal !important;
  word-break: break-word !important; */
  resize: vertical; 
  overflow-y: auto; /* Enable scrollbars for overflow */
  min-height: calc(1.5em * 5); /* Mimic 5 rows (default height) */
  max-height: 500px; 
  padding: 10px; 
  border-radius: 4px; 
  line-height: 1.5em; 
  white-space: pre-wrap; 
  word-wrap: break-word;
  width: '100%';
}

p {
  word-break: break-word !important;
  white-space: normal;
}
p + p {
  margin-top: 0px !important;
  white-space: normal !important;
  word-break: break-word !important;
}

.tiptap {
  > * + * {
    /* margin-top: 0.75em;
    margin-left: 1rem; */
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  .my-custom-class {
    background-color: #f0f0f0; /* Set background color */
    color: #333; /* Set text color */
    padding: 10px; /* Set padding */
    border-radius: 5px; /* Set border radius */
    /* Add any other custom styles you want */
  }

  .my-custom-list {
    list-style-type: disc !important;
    font-size: 15px;
  }

  .my-custom-ordered-list {
    margin: 0;
    padding: 0 0 0 20px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
    list-style-type: decimal;
  }

  .tiptap.ProseMirror.ProseMirror-focused {
    border: none !important;
    outline: none !important;
    max-width: 850px;
    white-space: nowrap !important;
  }

  .ProseMirror:focus {
    border: none !important;
    background-color: #090018;
    outline: none !important;
    max-width: 850px;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
