.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  background-color: #fcfcfc;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #d9d9d9;
}

.import-contacts-map-fields {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  height: 100%;
  overflow: auto;
  width: 100%;
  max-width: 1500px;
}

.import-options {
  position: fixed;
  left: 14%;
  z-index: 10;
  width: 100%;
  max-width: 240px;
  margin-top: 1rem;
  gap: 1rem;
  font-size: 1.1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px;
  padding: 1rem;
  border-radius: 8px;
}

/* stylelint-disable-next-line */
.steps-container .txtESM {
  width: 100%;
}
