
.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button{
    width: 36px !important;
    padding: 3px 10px !important;
}

.ql-picker-label{
    padding-top: 0 !important;
}

.ql-toolbar .ql-formats:nth-child(1){
    margin-left: 10px !important;
    color: #ADADAD;
    color: red;
}
.ql-toolbar .ql-formats:nth-child(2){
    border-right: 1px solid #ADADAD;
    border-left: 1px solid #ADADAD;
    padding-left: 15px;
    padding-right: 10px;
    margin-right: 5px;
    margin-left: 5px;
}
.ql-toolbar .ql-formats:nth-child(3){
    padding-left: 10px;
}
.ql-toolbar.ql-snow{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #F1F3F5;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 3px;
    text-align: center;
}

.lessonWrapper{
    display: flex;
    flex-direction: column;
    gap: 37px;
}
.lessonTitleNew{
    background-color: #F2EBFF;
    padding: 20px;
    border-radius: 10px;
}
.lessonTitleNew span{
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
    color: black;
}
.addLesson{
    padding: 0 20px;
    font-weight: bold;
    font-family: "Open Sans";
}
.lessonForm{
    margin: 0 auto;
}
.lessonInputs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.textEditor2{
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    align-items: start !important;
    padding: 0;
    gap: 0;
}

.editorNew{
    width: 100%;
    height: 46px;
    padding: 10px 26px;
    border: 1px solid #F1F3F5;
    border-radius: 10px 10px 0 0px;
    background-color: #F1F3F5;

}
.textFieldNew{
    min-width: 525px;
    min-height: 149px;
    border-radius: 0 0px 10px 10px;
}
.textFieldNew .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
    width: 390px !important;
    height: auto;
    min-height: 149px !important;
    margin: 0;
    padding: 25px !important;
    align-items: start !important;
}

.actionButtons{
    display: flex;
    width: 361px;
    gap: 37px;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px 37px;
    background-color: #f8f9fa;
    margin: 0 auto;
    border-radius: 8px;
}
.rightInfo{
    width: 40% !important;
}

.courseInfoLesson{
    flex: 1;
    width: 100% !important;
    margin: 0 auto;
}
.courseInfoLesson .leftInfo{
    width: 60% !important;
    border-right: 0.5px solid #d9d9d9 !important;
    padding: 0px 5% 0px 20px;

}
.courseInfoLesson .rightInfo{
    max-width: 35% !important;
}

/* media query */
@media screen and (max-width: 992px) {
    .lessonTitleNew{
        width: 85vw !important;
        margin: 0 auto !important;
    }
    .textEditor{
        max-width: 87vw !important;
    }
    .lessonInputs, .rightInfo{
        width: 80vw !important;
    }
  .helpRight{
        width: 85vw !important;
    }
        .textFieldNew .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
            width: 80vw !important;
        }
        .courseInfoLesson{
            flex: 1;
            width: 100% !important;
            margin: 0 auto;
        }
        .courseInfoLesson .leftInfo{
            width: 96% !important;
            border-right: 0 !important;
            padding: 0;;
        
        }
        .courseInfoLesson .rightInfo{
            max-width: 96% !important;
            margin: 0 auto;
        }

}