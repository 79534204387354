@import url('../../Styles/helper.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  background-color: var(--violet-500);
  color: var(--violet-800);
}

.navbar-expand-lg {
  padding: 0 30px;
}

.modal-btn {
  padding: 0.88rem 0.44rem;
  text-align: center;
  border: 1px solid #adadad;
  color: #adadad;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  width: 208px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.modal-btn-selected {
  padding: 0.88rem 0.44rem;
  text-align: center;
  color: #711fff;
  background-color: #daccf3;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  border: 1px solid #daccf3;
}

.navbar-brand {
  padding-top: 0;
  margin-right: 50px;
}

.hover {
display: flex;
align-items: center;
cursor: pointer;
}

.hover:hover {
  color: var(--violet-800);
}

.nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--violet-800);
  min-width: 120px;
  width: 100%;
  text-align: center;
}

.tab-to-hover:hover {
  background-color: var(--violet-200);
  color: var(--white-100);
}

.nav-item:last-child .nav-link {
  min-width: 50px;
}

.icons-group {
  margin-left: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-group li {
  list-style: none;
  margin-left: 25px;
}

.icons-group li svg {
  min-width: 20px;
  width: 100%;
  height: 25px;
}

/* stylelint-disable */
.sc-bdVaJa,
.fzhJWH {
  background-color: var(--violet-500) !important;
}

.sc-bxivhbm,
.sc-chPdSV,
.fOysLs {
  background-color: var(--violet-500) !important;
}

.sc-bxivhb,
.sc-chPdSV,
.exkSfS {
  background-color: var(--violet-500) !important;
}
/* stylelint-enable */

.drawer-table {
  border-radius: 10px 10px 0 0;
}

.drawer-table td {
  width: 350px !important;
}

.drawer-table thead {
  background-color: #f2f2f2;
  border: 1px solid #d9d9d9;
  border-radius: 10px 10px 0 0;
}
