
/* responsive */
.input-box .MuiOutlinedInput-notchedOutline{
  border: 2px solid #9152ff !important;
  border-top: 2px solid hsl(262, 100%, 66%) !important;
  background-color: #711FFF !important;
}

.css-gjbq6i-MuiInputBase-root-MuiFilledInput-root:after,
 .css-4u4t8f-MuiInputBase-root-MuiInput-root-MuiSelect-root:after, 
 .css-1a1fmpi-MuiInputBase-root-MuiInput-root:after{
  /* border: 1px solid #9152ff !important; */
}

/* discount */

@media (min-width: 1200px)
{
    .css-19qxmqf-MuiGrid-root {
        -webkit-flex-basis: 100% !important;
        -ms-flex-preferred-size: 100% !important;
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
}

.css-4u4t8f-MuiInputBase-root-MuiInput-root-MuiSelect-root:before{
  /* border-bottom: none !important; */
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  /* color: #711FFF !important; */
}


/* customerDetail */
.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #711fff !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-family: 'Open Sans' !important;
}
.css-1aquho2-MuiTabs-indicator,
.css-11yukd5-MuiTabs-indicator {
    height: 2.5px !important;
    background-color: #711fff !important;
}
.css-1a4cg4j-MuiButtonBase-root-MuiTab-root{
        font-size: 16px !important;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        font-family: 'Open Sans' !important;
}


.css-6hp17o-MuiList-root-MuiMenu-list{
    max-height: 100% !important;
  }

/* addNewCourses */

.css-1x51dt5-MuiInputBase-input-MuiInput-input, 
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-size: 14px !important;
    line-height: 19px !important;
}
.css-1717ajk-MuiFormLabel-root-MuiInputLabel-root{
    color: #03071e !important;
    line-height: 14px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Open Sans";
    /* text-align: left; */
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    /* border-color:#d9d9d9 !important; */
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
    height: 195px !important;
}
.field .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
    height: 270px !important;
}

.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before{
    /* border-color: #711FFF !important; */
}

/* new lesson */

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
    align-items: start !important;
}























