@import url('../../Styles/helper.css');

.backdrop {
  position: fixed;
  top: 4rem;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: var(--white-100);
  z-index: 1;
  padding: 0.44rem 0.88rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* stylelint-disable*/
.MUI-P6-M5 {
  transition: all 0.05s ease-in-out;
}

.MUI-P6-M5:focus {
  border-radius: 4px;
  outline: 2px solid #9c27b0;
}
/* stylelint-enable*/
.close-modal {
  width: 98px;
  height: 8px;
  background-color: var(--black-10);
  border-radius: 30px;
  display: block;
  margin: 20px auto;
  border: none;
}

.m-30p {
  margin-top: 30px;
}

.modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  color: var(--black-100);
  font-family: 'Open Sans', sans-serif;
}

.row {
  height: auto;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: 'Open Sans', sans-serif;
}

.sub-title2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: var(--black-100);
  font-family: 'Open Sans', sans-serif;
}

select {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  padding-right: 10px;
  margin-bottom: 20px;
  color: var(--black-40);
  margin-left: auto;
  max-width: 327px;
  width: 100%;
}
/* stylelint-disable*/
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #fff;
}

.modalSpan {
  font-weight: 500;
  font-size: 16px;
  color: var(--black-100);
  min-width: 120px !important;
  font-family: 'Open Sans', sans-serif;
}
/* stylelint-enable*/
.input-groups label {
  color: var(--black-50);
  font-family: 'Open Sans', sans-serif;
}

.input-groups {
  display: flex;
  max-width: 500px;
  width: 100%;
}

.input-groups input {
  max-width: 320px;
  width: 100%;
  margin-left: auto;
  border: none;
  margin-bottom: 20px;
  height: 18px;
}

::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: var(--black-40);
}

input[type='date']:invalid::-webkit-datetime-edit {
  color: #999;
}

.sm-width {
  width: 86px !important;
}

.button-groups {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  justify-self: flex-end !important;
  margin: 1rem 0 !important;
  width: 100%;
  padding: 0 2rem;
}

.cancel-btn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--black-80);
  background: none;
  border: none;
}

.create-btn {
  font-weight: 500;
  background-color: #711fff;
  border-radius: 5px;
  color: var(--white-100);
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  padding: 0 2rem;
  height: 38px;
}

.create-btn2 {
  font-family: 'Neue Haas Display Pro', 'Open Sans', sans-serif;
  font-weight: 500;
  height: 38px;
  background-color: #711fff;
  border-radius: 5px;
  color: var(--white-100);
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 2rem;
}

/* stylelint-disable */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #000 !important;
}

.Editor-para1,
.Editor-para2,
.Editor-para55 {
  position: relative;
}

.Editor-para1 #mui-rte-container {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 4px;
  border: 1.5px solid #fff;
  transition: all 0.2s ease-in-out;
}

.Editor-para2 #mui-rte-container {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 8px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.Editor-para5 #mui-rte-container {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 8px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.Editor-para1 #mui-rte-container:focus-within,
.Editor-para1 #mui-rte-container:focus {
  border: 1.5px solid #5a0bdf;
}

.Editor-para1 > div {
  width: 100%;
}

.Editor-para1 #mui-rte-container:hover {
  border: 1.5px solid #5a0bdf;
}

.Editor-para1 #mui-rte-container div:nth-child(2) {
  position: relative;
  margin-top: 0px;
  padding: 14.5px 16px;
  overflow-x: hidden;
  height: 100px;
  overflow-y: auto;
}

.Editor-para2 #mui-rte-container div:nth-child(2) {
  position: relative;
  margin-top: 0px;
  padding: 14.5px 0;
  width: 100%;
  overflow-x: hidden;
  height: 100px;
  overflow-y: auto;
}

.Editor-para5 #mui-rte-container {
  width: 100% !important;
}

.Editor-para5 #mui-rte-container div:nth-child(2) {
  position: relative;
  margin-top: 0px;
  padding: 0 0.44rem;
  width: 600px !important;
  overflow-x: hidden;
  height: 180px;
  overflow-y: auto;
}

.Editor-para10 #mui-rte-container div:nth-child(2) {
  position: relative;
  margin-top: 0px;
  padding: 0 0.44rem;
  width: 600px !important;
  overflow-x: hidden;
  height: 65px !important;
  overflow-y: auto;
}

.Editor-para15 #mui-rte-container div:nth-child(2) {
  position: relative;
  margin-top: 0px;
  padding: 0 1.4rem;
  width: 100% !important;
  overflow-x: hidden;
  height: 75px !important;
  overflow-y: auto;
}

.Editor-para15 #mui-rte-container {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 8px;
  border: none;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: none !important;
  border: 1px solid gray;
}

.Editor-para10 #mui-rte-container {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 8px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.Editor-para6 #mui-rte-container div:nth-child(2) {
  position: relative;
  margin-top: 0px;
  padding: 0.88rem 0.44rem;
  width: 600px !important;
  overflow-x: hidden;

  overflow-y: auto;
}

/* stylelint-enable */
