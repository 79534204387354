@import url('../../Styles/helper.css');

.side-menu {
  background-color: #efeff5;
  height: 100vh;
  padding-top: 8.8888%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden auto;
  padding-bottom: 4.8888%;
  justify-content: center;
}

.lg-out {
  line-height: 19px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.3px;
  color: inherit;
}

.data-list {
  height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 0.5rem;
  list-style: none;
  scrollbar-width: none; /** Firefox */
  -ms-overflow-style: none; /** IE/Edge */
  padding-left: 35px;
}

.data-list li {
  margin-bottom: 5px;
  cursor: pointer;
}

.img-box {
  display: inline-block;
  max-width: 30px;
  width: 100%;
  margin-right: 21px;
}

a:hover {
  color: #711fff;
}

.route-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--black-100);
  font-family: 'Open Sans', sans-serif;
}

.active-dropdown .route-link {
  margin-bottom: 10px;
}

.data-list li a {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 44px;
  letter-spacing: -0.3px;
  color: #03071e;
  text-decoration: none;
}

.data-list > li:hover > a {
  color: #711fff;
}

/* stylelint-disable-next-line  */
.sub-route li a {
  line-height: 19px;
}

.sub-route {
  padding-left: 0;
  position: relative;
  height: auto;
  overflow: hidden;
  transition: all ease-in-out 0.5s;
}

.sub-route::before {
  content: '';
  height: 100%;
  width: 2px;
  position: absolute;
  left: 10px;
  top: 0;
  background: #9152ff;
}

.child-box {
  display: inline-block;
  max-width: 30px;
  width: 100%;
  margin-right: 21px;
  position: relative;
  height: 10px;
}

.sub-route li {
  margin-bottom: 10px;
}

.sub-route > li:hover > a {
  color: #711fff;
}

.active-li {
  color: #711fff !important;
}

.avatar-box {
  background: #fcfcfc;
  border-radius: 6px;
  padding: 2px;
  max-width: 180px;
  width: 100%;
  height: 48px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.avatar-box p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #03071e;
}

/* stylelint-disable-next-line */
.avatar-box .MuiAvatar-root {
  width: 24px;
  height: 24px;
}

.route {
  margin-bottom: 30px;
}

.route .parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.route .child {
  display: flex;
  flex-direction: column;
  margin-left: 11px;
  margin-top: 10px;
  border-left: 2px solid var(--violet-600);
  padding-left: 34px;
  transition: all 0.3s ease-in-out;
}

.child .route-link:last-child {
  margin-bottom: 0 !important;
}

.route-link-active {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #711fff;
  font-family: 'Open Sans', sans-serif;
}

.route-icon {
  margin-right: 21px;
  transition: all 0.2s ease-in-out;
}

.route-icon svg {
  width: 21px;
  height: 22px;
}

.subroute {
  padding-left: 45px;
  margin-bottom: 10px;
  position: relative;
}

.offcanvas-bottom {
  height: auto !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}

/* stylelint-disable-next-line */
.notActive-dropdown {
  min-height: 0;
  overflow: hidden;
  margin-left: 11px;
  padding-left: 34px;
  height: 0;
  transition: 0.3s;
  opacity: 0;
}

.active-dropdown {
  display: flex;
  flex-direction: column;
  margin-left: 11px;
  margin-top: 10px;
  border-left: 2px solid var(--violet-600);
  padding-left: 34px;
  transition: all 0.3s ease-in-out;
  min-height: auto;
  opacity: 1;
}

.active-dropdown .route-link:last-child {
  margin-bottom: 0;
}

@media only screen and (width <= 1310px) {
  .data-list {
    padding-left: 15px;
  }

  .data-list > li > div > a {
    font-size: 0.79rem;
  }

  .user-container {
    background: #fff;
    padding: 0.2rem 0.8rem;
    border-radius: 4px;
    font-size: 0.79rem;
    position: absolute;
    bottom: 80px;
    left: 15px;
    cursor: pointer !important;
  }
}

@media only screen and (width <= 1199px) {
  .side-menu {
    display: none;
  }

  .side-menu2 {
    display: flex !important;
  }

  .navbar-toggler {
    display: none;
  }

  .icons-group {
    display: none;
  }
}
