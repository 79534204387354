

/* media query */
@media screen and (max-width: 1338px) {
    .nony{
        display: none;
    }

    .mobileView{
        display: flex;
        flex-direction: column !important;
    }
    .blakishSection{
        width: 100% !important;
    }
    .usersHeader{
        width: 96% !important;
    }
    .userLists{
        width: 100% !important;
    }
    .primaryInfo{
        width: 100% !important;
    }
    
}
