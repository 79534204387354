.kpi-text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}

.kpi-middle-container {
  width: 87.62%;
  padding: 0 15px;
}

.kpi-middle {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.middle-title {
  font-size: 16px;
  color: #310085;
}

.kpi-barchart {
  display: flex;
  flex-direction: column;
  gap: 31px;
}

.legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  gap: 22px;
  background: #f7f7f7;
  border-radius: 8px;
  width: max-content;
  margin: 0 auto;
}

.legend-actual-hours {
  display: flex;
  align-items: center;
  gap: 10px;
}

.legend span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  gap: 9px;
  color: rgb(3 7 30 / 80%);
}

.recharts-surface {
  padding: 22px;
}

.export-tooltips-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f7f7f7;
}

.export-tooltips-container span {
  font-size: 1rem;
  font-weight: 600;
}
