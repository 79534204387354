.createHelpWrapper{
    height: 93vh;
    overflow-y: auto;
    width: 100%;
}

.netUWrapperHelp{
    min-height: 711px;
    display: flex;
    flex-direction: column;
    gap: 37px;
    padding: 2%;
    padding-bottom: 40px;
    border-radius: 10px;
    margin-left: 12px;
    border: 1px solid #d9d9d9;
    margin: 1.38%;
    align-items: center;
    background-color: white;
    width: 97.2%;
}

.filledBox{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #F1F3F5;
    height: auto !important;
    box-shadow: none;
    border-radius: 5px;
    }
    .lessonWrapper{
        flex: 1;
        width: 95%;
    }
    .lessonTitleNew{
        width: 100%;
    }
    .backButtons{
        flex: 1;
    }
    .courseTitle{
        font-size: 24px;
        color: #711FFF;
        font-family: "Open Sans";
        font-weight: 600;
        line-height: 100%;
        width: auto;
    }
    .courseInfo{
        flex: 1;
        width: 80%;
        margin: 0 auto;
    }

    .courseDesc{
        line-height: 100%;
        font-size: 16px;
        font-family: "Open Sans";
        color: black;
        max-width: 100% !important;

    }
    .lessonForm{
        flex: 1;
        width: 100%;
    }

    /* media query */

    @media screen and (max-width: 992px) {
        .courseWrapper{
            width: 100vw
        }
        .filledBox{
            width: 85vw;
        }
        .chapterName, .chapters{
            width: 80vw;
        }
        .chapters .css-1db35tm-MuiPaper-root{
            width: 80vw !important;
        }

    }

    @media (min-width: 992px) and (max-width: 1250px){

        .courseInfo{
            gap: 5% !important;
            justify-content: space-between;
        }
        .leftInfo{
            width: 40% !important;
            padding: 0;
            border-right: none !important;
        }
    }