

.leftInfo .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input{
    margin-left: 20px;
    margin-top: 14px;
}

.courseNew {
    padding: 3%;
    border-radius: 10px;
    background-color: white;
    margin: 0 1.38% 1.38% 1.38%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}
.courseInfo{
    display: flex;
    gap: 5%;
}
.leftInfo{
    width: 40% !important;
    display: flex;
    flex-direction:column ;
    font-family: "Open Sans" !important;
    margin: 0 auto;
    flex: 1;
}
.rightInfo{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 40% !important;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    gap: 20px;
    align-items: center;
    font-size: 12px;
    font-family: "Open Sans";
    padding: 30px;
}
.videoTags{
    min-height: 196px;
    width: 80%;
    padding: 30px;
    margin: 0 auto !important;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    align-items: center;
    align-content: center;
}
.internalBox{
    min-height: 136px;
    display:flex;
    flex-direction: column;
    margin: 0 auto !important;
    gap: 16px;
}

.chips{
    width: 443px;
    min-height: 32px;
}


.bottomActionButtons{
    width: 218px;
    height: 31px;
    margin-bottom: 20px;
    display: flex;
    gap: 44px;
    text-align: center;
}
/* media query */

@media (max-width: 992px) {
    .courses{
        width: 80vw !important;
        margin: 1.38% auto !important;
    }
    .courseInfo{
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    .full{
        width: 100% !important;
    }
    .courseNew{
        min-width: 96%;
        padding: 30px;
        margin: 0 auto !important;
    }
    .yourCourses{
        width: 80vw !important;
        margin: 0 auto !important;
    }
    .full .leftInfo{
        width: 100% !important;
        padding: 0px;
        border-right: none !important;
    }
    .full .rightInfo{
        align-content: center;
        margin: 0 auto;
        width: 100% !important;
    }
    .videoTags{
        width: 100%;
    }
    .leftInfo .MuiInputBase-root{
        width: 100% !important;
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .videoTags{
        width: 100%;
    }
    .rightInfo{
        margin: 0;
    }
    .courseInfo{
        width: 100%;
    }
    .full{
        width: 100% !important;
    }
}
