@import url("../../Styles/helper.css");

/* stylelint-disable */

.editor {
  box-sizing: border-box;
  cursor: text;
  border-radius: 4px;
  background: var(--grey-300);
  width: 97%;
  padding: 16px;
  margin: auto !important;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.editorNoteContacts {
  box-sizing: border-box;
  cursor: text;
  border-radius: 4px;
  background: var(--grey-300);
  width: 97%;
  padding: 16px;
  margin: auto !important;
  
}

.editorNoteContacts>div {
  display: flex;
  min-height: 65px;
  flex-direction: column;
  justify-content: space-between;
  width:99%
}

.note #mui-rte-container{
  display: flex;
  flex-direction: column-reverse;
  border-radius: 4px;
  border: 1.5px solid #fff;
  transition: all 0.2s ease-in-out;
}

.note #mui-rte-container div:nth-child(2) {
  position: relative;
  margin-top: 0;
  padding: 0 0.44rem;
  width: 100%;
  overflow-x: hidden;
  height: 100px;
  overflow-y: auto;
}



.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

.editor .DraftEditor-root {
  position: relative !important;
  max-height: 400px !important;
  min-height: 60px !important;
  height: auto !important;
  overflow-y: auto;
}

.toolbar {
  display: flex;
  column-gap: 10px;
  color: '#888'
}

.toolbar button {
  background: transparent;
  border: none;
  color: '#888'
}

.t16lpgj>div>span>span {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  background: linear-gradient(0deg, #f2ebff, #f2ebff);
  box-shadow: 2px 2px 6px 0px #d9d9d9;
  padding: 20px;
  border-radius: 8px;
}

.editor .sc-bdVaJa {
  background: transparent !important;
  text-decoration: underline;
  color: var(--violet-500);
}

.MuiTypography-root>div>p>a {
  text-decoration: underline;
  color: var(--violet-500);
}

.HPEJw {
  outline: none;
  vertical-align: text-bottom;
  height: 34px;
  width: 250px;
  border: none;
  background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px 0 #d9d9d9;
}

.t16lpgj>div>span>span .bi09khh button {
  background: white !important;
  border: none;
  padding: 4px 10px;
  border-radius: 4px;
  fill: #711fff;
}


.bc4rxid>svg {
  fill: #caccd0;
  height: 20px;
  width: 20px;
}

.mention-dileverable {
  color: #5fcea8;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  padding: 2px;
  background-color: #f3e1ed;
}

/* stylelint-enable */