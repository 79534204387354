:root {
  --primary-color: #711fff;
  --light-primary-color: #dcd4fd;
  --dark-primary-color: #5a0bdf;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* stylelint-disable-next-line */
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  /* stylelint-disable-next-line */
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--primary-color);
  box-shadow: inset 0 0 0 4px var(--light-primary-color);
  transition: all 0.3s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-primary-color);
}
