@import url("../../Styles/helper.css");

.stream {
  position: relative;
  padding-right: 30px;
  background-color: var(--grey-300);
  height: 100vh;
}

.chat-header {
  padding: 40px 0px 10px;
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 999;
  background-color: var(--grey-300);
}
.fukrah{
  color: #29a5b6;
}
.mention-person {
  color: #711FFF;
  padding: 2px 2px;
  background-color: #f3e1ed;
  font-weight: 600;
}

.chat-header-top {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.chat-header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-nav-left button {
  background: white;
  color: var(--black);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  min-width: 230px !important;
  height: 39px;
  border-right: 0.5px solid #a3a9ab;
  border-radius: 6px 0px 0px 6px;
}

.chat-nav-left button:nth-child(1),
.netnetc-inc1 {
  width: 136px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  gap: 10px;
  width: max-content;
  height: 40px;
  background: #ffffff;
  border: 0.5px solid #a0a2aa;
  border-radius: 6px 0px 0px 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #000000;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
}

.chat-nav-left button:nth-child(2),
.netnetc-inc2 {
  display: flex;
  justify-content: center;
  width: 136px !important;
  align-items: center;
  padding: 10px 10px;
  height: 40px;
  background: #ffffff;
  border-width: 0.5px 0.5px 0.5px 0px;
  border-style: solid;
  border-color: #a0a2aa;
  border-radius: 0px 6px 6px 0px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.chat-nav-left button:nth-child(3),
.netnetc-inc3 {

  border: 0.7px solid #7b61ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  width: 136px !important;
  height: 40px;
  background: #ffffff;
  border: 0.7px solid #7b61ff;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;
  color: #000000;
}

.netnetc-inc3:hover,
.netnetc-inc2:hover,
.netnetc-inc1:hover {
  background: #c8a7ff;
  font-weight: 700;
}

.chat-nav-left button:last-child {
  border-right: 0px;
}

.chat-nav-right {
  max-width: 1060px;
  width: 100%;
  display: flex;
}

.right-input {
  position: relative;
  margin-left: auto;
}

.search-btn {
  width: 47px;
  height: 40px;
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #676b6b !important;
  border: 0.5px solid #a3a9ab;
  border-radius: 6px;
}

.right-input input {
  width: 100%;
  height: 38px;
  border: none;
  padding: 11px 40px;
}

.right-input input::placeholder {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: var(--black-60);
}

.chat-body {
  background: white;
  padding: 24px;
  width: 100%;
  min-height: 77vh;
  height: auto;
}

.chat-section {
  height: 550px;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

.msg-box {
  position: relative;
  border-top: 1px solid var(--grey-700);
  padding: 40px 0px;
}

.msg-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: var(--black-60);
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
}

.go-to-chat {
  position: absolute;
  right: 10px;
  top: -12px;
  font-family: "Neue Haas Grotesk Text Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border: none;
  color: var(--teal-500);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor {
  cursor: text;
  background: var(--grey-300);
}

.editor4 {
  box-sizing: border-box;
  cursor: text;
  background: #fff;
  position: absolute;
  bottom: 10%;
  width: 97%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.editor4>div {
  display: flex;
  min-height: 72px;
  flex-direction: column-reverse;
  justify-content: space-between;
}


.editor4>div>.t16lpgj {
  width: 100%;
  background: #f2ebff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 37px;
}

.editor4>div>.DraftEditor-root {
  padding: 1.5rem
}

.editor #mui-rte-container {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 8px;
  background-color: var(--grey-900);
}

.editor #mui-rte-container div:nth-child(2) {
  position: relative !important;
  padding: 5px 10px;
  height: 70px;
  overflow-y: auto;
  margin-top: 0px;
}

#mui-rte-H2-button {
  display: none;
}

#mui-rte-Undo-button {
  display: none;
}

#mui-rte-Redo-button {
  display: none;
}

#mui-rte-Highlight-button {
  display: none;
}

#mui-rte-link-control-button {
  display: none;
}

#mui-rte-media-control-button {
  display: none;
}

#mui-rte-OL-button {
  display: none;
}

#mui-rte-Blockquote-button {
  display: none;
}

#mui-rte-Code\ Block-button {
  display: none;
}

#mui-rte-Save-button {
  display: none;
}

#mui-rte-Clear-button {
  display: none;
}

.msg-head {
  display: flex;
  align-items: center;
  gap: 5px;
}

.msg-head .MuiAvatar-root {
  height: 42px;
  width: 42px;
}

.msg-box .username {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--black-100);
  letter-spacing: -0.3px;
}

.msg-body {
  padding-left: 48px;
}

.msg-para {
  margin: 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--black-100);
}

.inner-msg-box {
  padding: 15px 0px;
}

.inner-msg-header {
  display: flex;
  align-items: center;
  gap: 5px;
}

.inner-msg-body {
  padding-left: 48px;
}

.inner-msg-header .username {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--black-100);
  letter-spacing: -0.3px;
}

.time {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--black-40);
}

.sendIcon {
  border: none;
  background: transparent;
  position: absolute;
  right: 12px;
  bottom: 20px;
  color: var(--black-40);
}

#long-menu>div,
#long-menu2>div {
  min-width: 462px !important;
}

#long-menu li,
#long-menu2 li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#long-menu li .menu-detail,
#long-menu2 li .menu-detail {
  display: flex;
  gap: 5px;
}

#long-menu {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
}

/* WebKit (Chrome, Safari) */
#long-menu::-webkit-scrollbar {
  display: none;
}

#long-menu2 {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#long-menu2::-webkit-scrollbar {
  display: none;
}


.menu-detail div .MuiAvatar-root {
  width: 23px;
  height: 23px;
  font-size: 12px;
}

.member-detail {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: var(--black-40);
  margin-top: 5px;
}

.notify {
  width: 18px;
  height: 18px;
  background-color: var(--teal-500);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--white-100);
  margin-left: 11px;
}

.dropdown-group {
  display: flex;
  align-items: center;
  margin-left: auto;
  column-gap: 3px;
}

.dropdown-group .MuiAvatar-root {
  height: 23px;
  width: 23px;
  font-size: 12px;
}

.dropdown-group .chat-number {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: var(--black-50);
}

.mention {
  color: var(--violet-400);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.3px;
}

.mention::before {
  content: "@";
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.3px;
}

.no-msg-box {
  background-color: var(--grey-100);
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.no-msg-box h3 {
  color: var(--violet-400);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  margin-bottom: 10px;
}

.no-msg-box p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--black-60);
}

.netnetc-btn-act {
  width: 136px !important;
  height: 40px;
  background: #711fff;
  /* border: 0.5px solid #a0a2aa; */
  border-radius: 6px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.netnetc-btn-act2 {
  height: 40px;
  background: #711fff;
  border: 0.5px solid #a0a2aa;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.57rem;
  padding: 0.44rem 0.88rem;
  /* font-family: 'Open Sans', */
}

.g10-p5-bg-v-1 {
  background: linear-gradient(0deg, #f2ebff, #f2ebff);
  border: 1px solid #d9d9d9;
  box-shadow: 2px 2px 6px 0px #d9d9d9;
}

.netnetc-slect {
  border: 1px solid #a3a9ab;
  background-color: #fff;
  padding: 8px 10px;
  min-width: 145px;
  max-width: max-content;
  /* font-family: "Open Sans"; */
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.07em;
  font-size: 12px;
  height: 43px;
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}

.netnetc-slect:hover {
  background-color: #ebeaea;
}

.is_responed {
  color: #9152ff;
  outline: none;
  border: none;
  background-color: inherit;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

p.MuiTypography-root.MuiTypography-body2.MuiListItemText-secondary.css-83ijpv-MuiTypography-root p {
  margin-bottom: 4px;
}

p.MuiTypography-root.MuiTypography-body2.MuiListItemText-secondary.css-83ijpv-MuiTypography-root ul li {
  list-style: disc;
}

.reply-Editor {
  /* border: 1px solid #ddd; */
  cursor: text;
  border-radius: 8px;
  /* box-shadow: inset 0px 1px 8px -3px #ababab; */
  background: #f1f3f5;
  padding: 2px;
  min-height: 100px;
  max-width: 60%;
  margin-left: 17px;
  max-height: 250px;
  overflow-y: auto;
  scrollbar-width: 'none'
}

.reply-Editor>div>.DraftEditor-root {
  padding: 0.44rem 0.88rem
}


#dor-menu .MuiPaper-root {
  max-width: 248px !important;
  width: 100%;
  border: 1px solid rgba(3, 7, 30, 0.2);
  border-radius: 8px;
  color: #03071e;
}

#dor-menu .MuiPaper-root ul {
  padding: 0px;
}

#dor-menu .MuiPaper-root li {
  padding: 16px 20px;
  display: flex;
  gap: 10px;
}

#dor-menu .MuiPaper-root li:last-child {
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .search-btn {
    display: none !important;
  }

  .g10-p5-bg-v-1 {
    padding: 0.5rem !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 0.5rem !important;
  }

  .netnetc-slect {
    min-width: 111px;
    font-size: 11px;
  }
}

/* ARCHIVED CHAT */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2ebff;
  border: 1px solid #d9d9d9;
  box-shadow: 2px 2px 6px #d9d9d9;
  border-radius: 8px 8px 0px 0px;
  padding: 18px 27px;
}

.table-header h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #767676;
  margin-bottom: 0px;
}

.table-header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.table-header-right button {
  background: #ffffff;
  border: 0.5px solid #a3a9ab;
  border-radius: 6px;
}

.table-header-right button a {
  color: #616161;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.3px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.table-header-right input {
  padding: 0px;
}

.Archived-table-row {
  cursor: pointer;
}

.Archived-table-row td {
  min-width: 300px;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.respose {
  display: flex;
  gap: 10px;
}

.response-btn {
  background-color: transparent;
  color: #9152ff;
  border: none;
  display: inline-block;
  margin-left: 17px;
}

.response-avatar div {
  width: 23px;
  height: 23px;
  font-size: 13px;
}

@media screen and (max-width: 933px) {
 

  .netnetc-inc1{
    width: 90;
  }

  .netnetc-btn-act {
    width: 100px;
  }

 
}

@media screen and (max-width: 1300px) {

  .netnetc-slect {
    min-width: 110px;
  }

  .netnetc-btn-act {
    width: 120px;
  }

  #long-menu>div,
  #long-menu2>div {
    min-width: 345px !important;
  }
}




@media screen and (max-width: 520px) {
  .oppor-section {
    padding: 20px;
  }

  .table-header {
    padding: 10px;
  }

  .table-header h3 {
    font-size: 14px;
  }

  .table-header-right button a {
    font-size: 12px;
    gap: 0px;
  }

  .table-header-right button {
    padding: 4px;
  }
}

.m6zwb4v.mention-person::before {
  content: "@" !important;
}

.mention-person::before {
  content: "@" !important;
}

.m6zwb4v.mention-dileverable::before {
  content: "~" !important;
}

.mention-dileverable::before {
  content: "~" !important;
}

.m6zwb4v.mention-task::before {
  content: "/" !important;
}

.mention-task::before {
  content: "/" !important;
}