.alert {
  margin-top: 1rem;
}

.job-report-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
  font-size: 1.2rem;
}

.report-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.report-header h2 {
  padding: 0.5rem;
}

.report-header span {
  font-size: 0.9rem;
  color: var(--primary-color);
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 0.6rem;
}

.report-header .text-for-export {
  font-size: 1.1rem;
}

.refresh-btn {
  margin-top: 0.8rem;
  color: var(--primary-color);
  background: none;
  border-radius: 50%;
}

.loading-icon {
  color: var(--primary-color);
}

.refresh-btn .loading,
.loading-icon {
  animation: spin 1s linear infinite;
}

.divider {
  height: 100%;
  border-left: 2px solid var(--primary-color);
  margin-top: 0.5rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* * Sales Report */

.sales-report-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  justify-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin: 0 1rem;
}

.sales-report-card,
.sales-report-card-subtotal {
  display: flex;
  border-radius: 8px;
}

.sales-report-card {
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  max-width: 300px;
  height: auto;
  padding: 1rem;
  padding-top: 0.5rem;
  margin: 1.5rem 1rem;
  cursor: pointer;
  font-weight: bold;
  color: #000;
}

.sales-report-card-subtotal {
  font-size: 12px;
  justify-content: space-between;
  font-weight: 500;
  width: 100%;
}

.sales-report-card-subtotal span {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
}

.sales-report-selects-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

/* stylelint-disable */
.css-19lvgm1-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}
