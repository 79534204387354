.netnetu-section {
  background-color: #fcfcfc;
  border-radius: 8px;
  padding: 20px;
  border: 0.4px solid #a0a2aa;
  overflow: auto;
}

.netnetu-btn {
  width: 170.33px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px 0px 0px 6px;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  border: none;
  font-weight: 500;
}

.netnetu-btn-act {
  border-radius: 8px;
  background-color: #954ed4;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  width: 170.33px;
  height: 40px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  border: none;
  font-weight: 500;
}

.btg-pq-ps-q5 {
  border-left: 0.4px solid #a0a2aa !important;
  border-radius: 0px 6px 0px 0px !important;
}

.g10-p5-bg-300 {
  background-color: var(--grey-300);
  box-shadow: 2px 2px 6px 0px #d9d9d9;
}

.pb-nnu-nn-bg-300-g-800 {
  width: 67px;
  height: 11px;
  background: #d9d9d9;
}

.txt-g-800 {
  color: #05abab;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
}

.btn-nnu-nn-bg-300-g-spTxt {
  border: none;
  outline: none;
  background: var(--grey-300);
  color: #711fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
}

.fs-sm-txt-pro-os600 {
  font-size: 1rem !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #03071E;

}

.m-0000 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: #03071E99 !important;

}

.clr-v-500:hover {
  color: #711fff !important;

}

.fs-txt-esm-os500-clr-v-500 {
  font-size: 0.9rem !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #711fff;
}

.fs-txt-esm-os-600-clr-g-300 {
  font-size: 0.8rem !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: var(--black-60);
}

.btn-group {
  width: 271px;
  height: 56px;
  background-color: #ffffff;
  gap: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-group a {
  background-color: transparent;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #03071e !important;
}

.btn-group a:last-child {
  color: #711fff !important;
}

.chapter-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #03071e;
  margin-bottom: 20px;
}

.video-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: #03071e;
  margin: 8px 0px;
}

.video-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(3, 7, 30, 0.6);
}

/* ALL COURSE CSS */
.btn-group-deliverable {
  width: 344px !important;
}

/* COURSE DETAIL PAGE */
.course-header {
  padding: 14px;
}

.course-row {
  background: #f7f7fa;
}

.col-top {
  display: flex;
  column-gap: 20px;
  align-items: center;
  padding: 8px 27px;
}

.col-bottom {
  margin-top: 25px;
  padding: 15px;
  background-color: #ffffff;
  display: inline-block;
  border: 1px solid #d9d9d9;
  border-radius: 9px;
}

.col-bottom * {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  margin-right: 20px;
  color: #03071e;
}

.col-bottom a:last-child {
  margin-right: 0px;
  color: #711fff;
}

.col-top .course-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 100%;
  color: #03071e;
}

.course-body {
  height: 78vh;
  overflow-y: auto;
}

.course-pic {
  background: url("./assets/Rectangle\ 187.png") no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 318px;

}

.pic-left {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-icon {
  font-size: 50px !important;
  color: #711fff;
}

.pic-right {
  flex: 0.5;
  color: #ffffff;

}

.detail-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
}

.span-group {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 33px;
}

.span-group span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.detail-para {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 12px;
  max-width: 495px;
  width: 100%;
}

.enroll {
  background: #711fff;
  border-radius: 5px;
  width: 142px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
}

.course-content {
  display: flex;
  justify-content: center;
  padding: 40px 14px;
  column-gap: 40px;
}

.content-left {
  flex: 0.5;
}

.content-left h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #03071e;
}

.content-left .summary {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #03071e;
}

.content-left .summary:nth-child(2) {
  margin-top: 20px;
}

.content-left img {
  width: 100%;
  margin: 30px 0px;
}

.content-right {
  flex: 0.5;
}

.content-right h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #03071e;
}

.chapter-loop {
  margin-top: 30px;
}

.chapter-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #03071e;
}

.chapter-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #03071e;
  margin-left: 20px;
}

.custom-checkbox {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

/* Style the checkbox when it is checked */


.chapter-item {
  margin-bottom: 24px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.chapter-item:last-child {
  margin-bottom: 0px;
}

.chapter-list {
  padding: 0;
}

.chapter-play {
  font-size: 26px;
  color: #711fff;
  cursor: pointer;
}

/* LESSON DETAIL PAGE */
.lesson-body {
  display: flex;
  column-gap: 16px;
  height: 75vh;
  overflow-y: auto;
}

.body-left {
  flex: 0.5;
}

.body-left h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: #711fff;
}

.body-left h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  color: #03071e;
}

.body-left h5 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #03071e;
  margin: 30px 0px;
}

.body-left p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(3, 7, 30, 0.5);
  margin: 20px 0px;
}

.body-left .para-2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #03071e;

}

.body-left img {
  width: 100%;
  margin-bottom: 20px;
}

.body-left button {
  width: 217px;
}

.body-right {
  flex: 0.5;
}

.body-right .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: transparent;
}

.body-right .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px;
}

.body-right .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px;
}

.body-right .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px;
  padding-top: 10px;
}

.body-right .css-15v22id-MuiAccordionDetails-root {
  padding: 0px 16px 16px;
}