
  .discountFlex{
    display: flex;
    justify-content: space-between !important;
    flex-direction: column;
    height: 91vh;
    margin: 1.38%;
  }
  .newForm{
      padding: 4%;
      border-radius: 10px;
      gap: 20%;
      background-color: white;
      box-shadow: 2px 2px 6px 0px #D9D9D9;
      width: 100%;
  }

.newDiscountForm{
  display: flex;
  justify-content: space-between;
  gap: 8%;
}

.Dis_info{
  width: 42%;
  flex: 1;
   height: 164px !important;

}
.info_title{
height: 26px;
width: 100%;
display: flex;
gap: 12px;
margin-bottom: 14px !important;

}
.info_title h3{
  height: 26px;
font-family: 'Open Sans';
font-size: 20px;
font-weight: 600;
line-height: 26px;
letter-spacing: 0.015em;
text-align: left;
color: #03071E;

}
.info_icon{
  width: 16px !important;
  height: 16px;
  color:#00E6B8
}
.amount_info{
  width: 42%;
  flex: 1;
}



.formSpan{
  width: 50%;
}
.sideFormSpan{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3%;
  flex: 1;
}
.left_side{
  width: 100%;
  flex: 1;
display: flex;
flex-direction: column;
gap: 10px;
}

.right_side{
  width: 100%;
  flex: 1;
}
.name{
padding: 3px 0px;

}
.name span{
font-family: Open Sans;
font-size: 16px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: #03071E;
}



  