:root {
    /* fonts */
    --font-open-sans: "Open Sans";
    /* font sizes */
    --font-size-2xs: 11px;
    --font-size-mini: 15px;
    --font-size-5xl: 24px;
    --font-size-xl: 20px;
    /* Colors */
    --color-blue: #711fff;
    --color-gray-100: rgba(0, 0, 0, 0.4);
    --color-gray-200: rgba(0, 0, 0, 0.5);
    --color-gray-300: rgba(0, 0, 0, 0.8);
    --color-black: #000;
    --color-gainsboro-100: rgba(217, 217, 217, 0);
    --color-white: #fff;
    --br-5xs: 8px;
}


.inner-app{
    background-color: #fff !important;
}
.videoSelectionWrapper{
    height: 100vh;
    overflow-y: auto;
}


.internal-wrapper2 {
   margin: 1.38%;
  width: 97.2%;
}
/*  */
.screenTwoContainer {
    display: flex;
    gap: 20px;
    height: 630px;
}
.screenSelectFormWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
}

.formSide2 {
    height: 100%;
    border-radius: var(--br-5xs);
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-100);
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 20px;
}
.selectScreen{
    width: 100%;

}



.vTitle2{
    font-size: 20px;
    height: 33px;
    margin-bottom: 8px;
}

.screenBorders {
    display: flex;
    justify-content: space-between;
    gap: 42px;
}

.leftScreen{
    border-radius: var(--br-5xs);
    border: 1px solid rgba(0, 0, 0, 0.8) ;
    width: 100%;
    max-width: 600px;
    height: 305px;
}
.rightScreen{
    display: flex;
    flex-direction: column;
    gap: 19px;
}
.rScreens{
    max-width: 242px;
    width: 242px;
    height: 132px;
    border-radius: var(--br-5xs);
    border: 1px solid rgba(0, 0, 0, 0.8);
}

.brokenBorder{
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    margin: 8px;
    border-radius: var(--br-5xs);
    border: 2px dashed rgba(0, 0, 0, 0.5);
    border-spacing: 30px !important
}

.brokenBorder.hasVideo {
    border: none;
    border-radius: 8px;
  }

.saveButton{
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
}

/* screen two video list */
.screenTwoVideoList{
        border-radius: var(--br-5xs);
        background-color: var(--color-white);
        border: 1px solid var(--color-gray-200);
        width: 100%;
        height: 100%;
        overflow: hidden;
        gap: 21px;
        overflow-y: auto;
}


.box2 {
    border-radius: var(--br-5xs);
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    min-height: 167px;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
    width: 96%;
    display: flex;
    gap: 21px;
    margin: 21px auto;
}
.box2:hover{
    background-color: #e9e9ed;
}
.wrapper{
    width: 98%;
    margin: 7px 3%;
    display: flex;
    gap: 2%;
    cursor: pointer;
    flex: 1;
}
.imageWrapper2{
    width: 100%;
    position: relative;
    flex: 1;
}

.insideContainer img {
    max-height: 100%;
    height: 151px;
    width: 236px;
    border-radius: 6px;
}
.vInfoWrapper2{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.lesson2 {
    width: 100%;
    height: 14px;
    margin-bottom: 9px;
    font-size: 12px;
    font-family: "Open Sans";
    color: var(--color-gray-300);
}

.lessonTitle2 {
    max-width: 287px;
    width: 100%;
    font-family: "Open Sans";
    font-size: 16px;
}

.lessonDescripton2 {
    max-width: 276px;
    min-height: 28px;
    height: auto;
    margin-bottom: 5px;
    line-height: 14px;
    font-size: 12px;
    font-family: "Open Sans";
    color: #711FFF;
    display: inline-block;
}

.more2 {
    display: flex;
    gap: 6px;
    color: #a3a9ab;
}

.learn-more2 {
    width: 86px;
    height: 14px;
    font-size: 10px;
    font-family: "Open Sans";
}
.more2 img{
    width: 16px;
    height: 11px;
    color: #a3a9ab !important;
    margin-top: 4px;
}

.logoAndAuthor{
    margin-top: 8px;
    min-height: 45px;
    height: 100%;
}
.logoAndAuthor img{
    height: 25px;
    width: 25px;
}
.author{
    padding: 2px 0;
    margin-top: 4px;
    height: 18px;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: #6e7476;

}

.insideContainer {
    position: relative;
}
.centerPlayIcon{
    position: absolute;
    top: calc(50% - 27px);
    left: calc(50% - 27px);

}
.centerPlayIcon img{
    width: 53px;
    height: 54px;
}
.smallPlayIcon{
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 6px 0px 5.5px 0px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 10px;
    gap: 3px;
}
.smallPlayIcon img{
    width: 15px;
    height: 15px;
}
.hidden{
    display: none;
}

/* media query */
@media screen and (max-width: 992px) {

    .screenBorders {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .formSide2{
        height: auto;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .screenSelectFormWrapper{
        margin-bottom: 20px;
        width: 351px;
        max-width: 351px;
    }
    .screenTwoContainer{
        height: 950px;
    }
    .vInfoWrapper2{
        display: none;
    }

    .centerPlayIcon{
        display: none;
    }
    .smallPlayIcon{
        display: none;
    }
    .screenTwoVideoList{
        max-width: 300px;
    }
    .box2{
        width: 90%;
    }
    .screenTwoContainer{
        justify-content: space-evenly;
    }
}

 @media screen and (min-width: 992px) and (max-width: 1400px) {

    .screenBorders {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .formSide2{
        height: auto;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .screenSelectFormWrapper{
        margin-bottom: 20px;
        max-width: 351px;
    }
    .screenTwoContainer{
        height: 950px;
    }

} 

@media (min-width: 992px) and (max-width: 1200px){
    .vInfoWrapper2{
        display: none;
    }

    .centerPlayIcon{
        display: none;
    }
    .smallPlayIcon{
        display: none;
    }
    .screenTwoVideoList{
        max-width: 300px;
    }
    .box2{
        width: 90%;
    }
    .screenTwoContainer{
        justify-content: space-evenly;
    }
}

.screenTwoWrapper .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: 1px solid black !important;
}

