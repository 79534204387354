.courseWrapper{
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
}

.netUWrapper{
    display: flex;
    flex-direction: column;
    gap: 37px;
    padding: 20px;
    border-radius: 10px;
    margin: 0 1.38%;
    border: 1px solid #d9d9d9;
    margin-bottom: 25px;
    align-items: center;
    background-color: white;
}

.filledBox{
    width: 1094px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #F1F3F5;
    height: auto !important;
    box-shadow: none;
    overflow: hidden;
    border-radius: 5px;
    }
.addYourChapter{
    margin-left: 0;
    margin-right: auto;
    padding-left: 20px;
}
    .courseTitle{
        font-size: 24px;
        color: #711FFF;
        font-family: "Open Sans";
        font-weight: 600;
        line-height: 100%;
        width: auto;
    }
    .courseDesc{
        line-height: 100%;
        font-size: 16px;
        font-family: "Open Sans";
        color: black;
        max-width: 100% !important;

    }
.youTubeLink{
    font-size: 16px;
    line-height: 100%;
    font-family: "Open Sans";
    color: #023fe3 ;
}
.chipsTag{
    height: 32px;
    padding: 4px;
}
.chapterName{
    width: 100%;
    height: 115px;
    border-radius: 4px;
    
}

.settings{
    display: flex;
    height: 40px;
    width: 181px;
    text-align: center;
    align-items: center;
    gap: 16px;
}
.chapters {
    margin-bottom: 20px;
}
.backButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 650px;
     align-self: center;
}
.chapters{
    width: 100%;
}
.chapters .css-1db35tm-MuiPaper-root{
    width: 100% !important;
}
/* media query */
@media screen and (max-width:992px) {
    .courseWrapper{
        width: 100vw
    }
    .netUWrapper{
       padding: 40px;
    
    }
    .filledBox{
        width: 85vw;
    }
    .chapterName, .chapters{
        width: 80vw;
    }
    .chapters .css-1db35tm-MuiPaper-root{
        width: 80vw !important;
    }
}
@media (min-width: 993px) and (max-width: 1200px){
    .chapterName{
        width: 100% !important;
    }
}