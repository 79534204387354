.items {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
    color: black;
    font-size: 1rem;
    font-weight: 700;
    overflow: hidden;
    padding: 0.2rem;
    min-width: 200px;
  }


  
  .item {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.4rem;
    display: block;
    margin: 0;
    padding: 0.2rem 1rem;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
  

    &:hover {
    background-color: #711fff; 
    color: white;
  }
  }

  .mention-icon {
    margin-left: 14px;
  }


