@import url('../../Styles/helper.css');

.menu {
    max-width: 225px;
    width: 100%;
    background-color: var(--white-100);
    border: 1px solid var(--black-20);
    border-radius: 8px;
    padding: 1rem 0;
    position: absolute;
    top: 80px;
    right: 20px;
    z-index: 99;
}

.menu-item{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--black-100);
    padding: 0.8rem;
    cursor: pointer;
}

.menu-item:hover{
    background-color: var(--violet);
    color: var(--white-100);
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}