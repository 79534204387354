
.superAdminWrapper{
    display: flex;
}

.d-none-in-mobile{
    min-width: 274px;
    width: 280px;
}

.mainContent{
    width: 100%;
    height: 100vh;
    flex: 1;
    background-color: #f7f7fa;
}

  @media (max-width:992px){
    .discountFlex{
        gap: 10%;
      }
    .newDiscountForm{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 12px !important;
    }
    .newForm{
      max-height:100% !important;
      height: 100%;
      max-width: 100vw;
      padding:24px auto;
      margin-left: 0px;
      margin: 0 auto !important;
        }
    .left_side, .right_side{
        width: 100% !important;
    }
    .left_side .formSpan{
        width: 40% !important;
    }
    
    .left_side input .MuiInputBase-root{
          width: 60% !important;
      }

      .right_side .formSpan{
        width: 40% !important;
      }
      .right_side input .MuiInputBase-root{
        width: 60% !important;
    }
    .info_title h3{
        font-size: 18px !important;
    }
    .d-none-in-mobile{
        display: none !important;
    }
    .css-2biptt-MuiDrawer-docked{
        display: none !important;
    }
    .css-1renpje-MuiDataGrid-root{
        width: 100vw !important;
    }
    
    .mainContent{
        width: 100vw !important;
        margin: 0 auto;
    }
    
    .superAdminWrapper .oppor-row{
        justify-content: space-between;
        max-width: 100vw !important;
        margin-right: 20px;
    }
  }

  .superAdminWrapper .oppor-row{
    max-width: 100% !important;
}
/* removing bottom navigation */

.removeBottomNavigationForSuperAdminPages {
  display: none;
}

.superAdminWrapper .MuiDataGrid-row {
  white-space: normal !important;
}

.breakSpace{
  white-space: break-spaces !important;
}