.timeline {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.deliver {
  width: 80%;
}

.e-spinner-pane {
  display: none !important;
}

.date_range {
  display: flex;
  justify-content: space-between;
  height: 0;
}

.progress {
  height: 0.74rem !important;
  width: 100%;
}

.deliver .progress {
  height: 1.5rem !important;
  background: #d2b8ff;
}

.prograssbar {
  padding: 5px;
}

.fpuQHT {
  margin-bottom: 0px;
  padding: 5px 15px !important;
  background-color: #ffbd85 !important;
  border-start-start-radius: 8px !important;
  border-start-end-radius: 8px !important;
  border-bottom: 3px solid #bb5805 !important;
}

.jCsFZr {
  color: rgb(32, 31, 31) !important;
  background-color: #e8e8e8 !important;
  padding: 10px 10px !important;
  border-start-start-radius: 8px !important;
  border-start-end-radius: 8px !important;
}

.hA-diKX {
  font-size: 12px;
  color: #4d4d4d;
  white-space: pre-wrap;
  padding: 0px 10px !important;
}

.css-1xof6pv-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.css-mm79k-MuiButtonBase-root-MuiAccordionSummary-root {
  flex-direction: row-reverse !important;
}

.deliver .progress-bar {
  height: 27px !important;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: #000000;
  overflow: visible;
  padding: 5px;
}

.span-due {
  background-color: #f1f3f5;
  border-radius: 8px;
  padding: 0.44rem 1.44rem;
  text-align: center;
  font-size: 0.8rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #5fcea8;
  height: fit-content;
  transition: all 0.3s ease-in-out;
}

.span-due:hover {
  background: #5fcea8;
  color: #000;
  font-weight: 600;
}

.success .progress-bar {
  background-color: #5fcea8;
}

.light-success .progress-bar {
  background-color: #5ee690;
}

.warning .progress-bar {
  background-color: #ff6827;
}

.danger .progress-bar {
  background-color: #dc241c;
}

.table-menu {
  margin: 0 auto;
}
.progress-bar {
  margin: 0px !important;
}
.progress-bar .bg-warning {
  background-color: #ff6827 !important;
  color: #5fcea8 !important;
}

.start {
  color: rgba(3, 7, 30, 0.4);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
}

.end {
  color: #03071e;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
}
.offcanvas-backdrop.show {
  opacity: 0.2 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: 0;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.css-rfuczr {
  height: 19px !important;
  width: 19px !important;
  font-size: 14px !important;
}

.firstMenu {
  width: 11.5%;
  left: 10px;
}
.secondMenu {
  left: 12.5%;
}

@media only screen and (max-width: 1560px) {
  .firstMenu {
    width: 14%;
    left: 10px;
  }
  .secondMenu {
    left: 15%;
  }
}

@media only screen and (max-width: 1310px) {
  .firstMenu {
    width: 14%;
    left: 5px;
  }
  .secondMenu {
    left: 15%;
  }
}

@media only screen and (max-width: 1200px) {
  .firstMenu {
    width: 20%;
    left: 40px;
  }
  .secondMenu {
    left: -0.2%;
  }
}
