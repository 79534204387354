body > .coursesWrapper{
    font-family: "Open Sans";
}
body{

    background-color: #F7F7FA;
}
.coursesWrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    overflow-y: auto;
}

.netNetU{
    height: 49px;
    background-color: #f2ebff !important;
    padding: 0 1.4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}




.title{
 width: 157px;
 height: 26px;
}
.title span{
    font-size: 20px;
    font-family: "Open Sans";
    font-weight: 600;
    color: #000;
    text-align: center;
    width: 113px;
}
.icons{
    display: flex;
    align-items: center;
    height: 40px;
    gap: 56px;
}

.courses{
    display: flex;
    height: 65px;
    padding: 5px 15px;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    background-color: white;
    margin: 1.38%;
    justify-content: space-between;
}
.cor{
    min-width: 100%;
}
.cor h2{
    
    margin: 5px 10px 5px 15px;
    font-size: 24px;
    font-weight: 600;
    color: #03071e;
    line-height: 40px;
}
.but{
    width: 108px;
    padding: 10px;
    margin-right: 25px;
    margin: auto 25px;
}

.courseList{
    padding: 3%;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    background-color: white;
    margin-right: 18px;
    margin-left: 20px;
    margin-bottom: 25px;
}
.yourCourses{
    position: relative;
    height: 49px;
    margin-bottom: 20px;
    min-width: 80%;
}
.yourCourses h3{
    margin-top: 0;
    margin-bottom: 7px;
    font-size: 20px;
    line-height: 130%;
    text-align: left;
    font-weight: bold;
    font-family: "Open Sans";
}
.yourCourses p{
    font-size: 16px;
    line-height: 100%;
    font-family: "Open Sans";
}
.lists{
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
/* courses lists */

.box {
    border: 1px solid #d9d9d9;
    background-color: white;
    padding-bottom: 6px !important;
    display: flex;
    gap: 54px;
    min-height: 184px;
    align-items: center;
}
.box:hover{
   background-color: #f4f4f1;
}

.innerBox{
    margin: 16px 17px;
    min-height: 152px;
    height: 100%;
    min-width: 559px;
    padding: 0 8px;
    display: flex;
    gap: 5%;
    position: relative;
    align-items: center;
}

.videoWrapper {
    text-decoration: none;
    max-height: 136px;
    position: relative;
}
.videoWrapper img {
    width: 100%;
    border-radius: 6px;
}
.centerPlayIcon {
    position: absolute;
    top: 46px;
    left: 92px;
}

.centerPlayIcon img {
    width: 53px;
    height: 54px;
}


.vInfoWrapper3 {
    text-decoration: none;
    width: 100%;
    height: 100%;
    max-height: 152px;
    display: flex;
    flex-direction: column;
}

.lesson3 {
    width: 100%;
    height: 14px;
    margin-bottom: 9px;
    font-size: 12px;
    font-family: "Open Sans";
    color: var(--color-gray-300);
}

.lessonTitle3 {
    max-width: 287px;
    height: 18px;
    width: 100%;
    margin-bottom: 8px;
    font-family: "Open Sans";
    font-size: 16px;
}

.lessonDescripton3 {
    min-height: 28px;
    height: auto;
    margin-bottom: 5px;
    line-height: 14px;
    font-size: 12px;
    font-family: "Open Sans";
    color: #711FFF;
    display: inline-block;
}

.more3 {
    display: flex;
    gap: 6px;
    color: #a3a9ab;
}

.learn-more3 {
    width: 86px;
    height: 14px;
    font-size: 10px;
    font-family: "Open Sans";
    color: #a3a9ab !important;
}
.vInfoWrapper3 a:hover{
    text-decoration: none !important;
}

 .more3:hover{
     color: #711FFF !important;
 }
.more3 img {
    width: 16px;
    height: 11px;
    color: #a3a9ab !important;
    margin-top: 3px;
}

.logoAndAuthor {
    margin-top: 8px;
    min-height: 45px;
    height: 100%;
}

.logoAndAuthor img {
    height: 25px;
    width: 25px;
}

.author {
    padding: 2px 0;
    margin-top: 4px;
    height: 18px;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: #6e7476;
    font-family: "Open Sans";
}

/* media query */
@media screen and (max-width: 767px) {
    .coursesWrapper .netNetU{
        width: 100vw;
    }
    .courses{
        min-width: 90vw;
    }
    .cor{
        min-width: 40vw;
    }
    .courseList{
        min-width: 90vw;
        padding: 15px;
        margin: 5px;
    }
    .lists .box{
        min-width: 40vw;
    }
    .videoWrapper img, .videoWrapper{
        max-width: 200px !important;
    }
    .colFlex{
        display: flex;
        flex-direction: column;
    }
    .overviewLeft{
        width: 80vw;
        padding: 45px 30px !important;

    }
    .overviewLeft h4{
        margin-left: 0 !important;
        margin-bottom: 6px;
    }
    .overviewRight{
        padding: 30px 30px !important;
        width: 100vw;
    }
    .overview{
        width: 100vw;
    }
}
        .collapseButton:hover {
            color: #711FFF;
            background-color: #d0d5d7;
        }
/* overview */
.overview{
    min-height: 212px;
    height: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #D9D9D9;
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    flex: 1;

}
.overviewLeft{
    padding: 45px 69px;
    height: auto;
    align-content: flex-start;
    flex: 1;
}
.overviewLeft h4{
    color: #711FFF;
    text-align: start;
    font-size: 10px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0.7px;
    font-family: "Open Sans";
}
.overviewRight{
    padding: 30px 100px;
    color: #711FFF;
    justify-content: center;
    background-color: #F6F6F6;
    flex: 1;
}

.chapterListStyle{
    list-style: none;
    font-size: 14px;
}

@media screen and (max-width: 992px) {
    .courseList{
        min-width: 90%;
    }
    .box, .courses{
        min-width: 96%;
    }
    .cor{
        min-width: 75%;
    }
}
