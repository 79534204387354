.authorDetailContainer{
    display: flex;
    flex-direction: column;
    /* width: 884px; */
    flex: 1 0 884px;
    align-items: flex-start;
    padding: 3.5%;
    gap: 25px;
    width: 93%;

}

.fullName{
    display: flex;
    gap: 10%;
    justify-content: space-between;
    width: 100%;
}


.authorDetail{
    display: flex;
    gap: 10%;
    justify-content: space-between;
    width: 100%;
}
.authorDetail .detailLeft{
    display: flex;
    flex-direction: column;
    gap: 86px;
    min-width: 300px;
    min-height: 213px;
    flex: 1;
}

.emailID p{
    color: var(--Black-Black-80, rgba(3, 7, 30, 0.80));
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 135.714% */
    letter-spacing: -0.3px;
}
.authorDetail .detailRight{
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: 45px;
    padding: 30px;
    height: 100%;
    justify-content: space-between;
}
.dropContainer{
    flex: 1 0 0;
    min-width: 300px;
    min-height: 217px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
}
.dropZone{
    display: flex;
    padding: 6px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--borderRadius, 4px);
    border: 1px dashed var(--Black-Black-40, rgba(3, 7, 30, 0.40));
    background: var(--Violet-Violet-900, #F2EBFF);
}
.dropZone .MuiDropzoneArea-root{ 
    width: 180px;
    min-height: 69px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center !important;
    border: none;
    flex-direction: column;
}
.dropZone .MuiDropzoneArea-icon{
    display: none;
}

.ImageIcon {
    display: flex;
    
    justify-content: center;
    text-align: center;
    align-items: center;
}
.ImageIcon p{
    width: 156px;
    margin-left: 1rem;
    color: #000;
    text-align: center;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
}
.recomended{
    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 116.667% */
}

.authorDesc{
    min-width: calc(100% - 2px);
    padding-left: 2px;
}

.authorDesc .ql-snow.ql-toolbar button, .authorDesc .ql-snow .ql-toolbar button{
    width: 42px !important;
    padding: 3px 10px !important;
}

/* .ql-toolbar.ql-snow .ql-formats {
    border-right: 1px solid #ADADAD;
} */
.ql-picker-label{
    padding-top: 0 !important;
}

.authorDesc .ql-toolbar .ql-formats:nth-child(1){
    margin-left: 10px !important;
    color: #ADADAD;
    /* display: flex;
    justify-content: space-between; */
    min-width: 168px !important;
    color: red;
}
.authorDesc .ql-toolbar .ql-formats:nth-child(2){
    min-width: 88px !important;
    border-right: 1px solid #ADADAD;
    border-left: 1px solid #ADADAD;
    padding-left: 15px;
    padding-right: 10px;
    margin: 0 auto;
}
.authorDesc .ql-toolbar .ql-formats:nth-child(3){
    min-width: 168px !important;
    padding-left: 10px;
}
.ql-toolbar.ql-snow{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #F1F3F5;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 3px;
    text-align: center;
}




.authorYoutubeLink{
 
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 45% !important;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        gap: 20px;
        align-items: center;
        font-size: 12px;
        font-family: "Open Sans";
        padding: 30px;
}


/* media query */
@media screen and (max-width: 992px) {
    .authorCoursesList{
        margin: 10px auto;
    }
    .authorDetailContainer{
        max-width: 93vw !important;
        /* gap: 7% !important; */
    }
    .fullName{
        flex-direction: column;
        gap: 20px;
    }
    .authorDetail{
        flex-direction: column;
        gap: 20px;
    }
    .authorDesc{
        max-width: 90vw !important;
        margin: 7% 0;
    }
    .authorYoutubeLink {
        width: 100% !important;
    }
    .textEditor{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    
}

@media (min-width: 993px) and (max-width: 1200px){
    .authorYoutubeLink{
      width: 50% !important;
    }
  }


