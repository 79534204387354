.authorDetailWrapper{
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow-y: auto;
}

.grid{
    width: 97.2%;
    margin: 1.38%;
    border-radius: 10px 10px 0px 0px;
}


/* author detail */

.authorProfile{
display: flex;
padding: 20px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 10px;
background: var(--Grayscale-Grey-1000, #F8F9FA);
margin-bottom: 20px;
}

.profile{
display: flex;
padding: var(--none, 0px);
align-items: center;
gap: 10px;
}

.authName{
  /* min-width: 294px; */
  min-height: 107px;
  display: flex;
  padding: var(--none, 0px) 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
}
.authName h3{
  color: var(--Violet-Violet-400, #5600EB);
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
.authName p{
  color: #000;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; 
}

.yLinks{
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: var(--Grayscale-Grey-800, #E9ECEF);
}

.Utube{
  color: var(--Violet-Violet-400, #5600EB);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; 
}
.yLinks p{
  color: var(--Blue-Blue-500, #0B88F9);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.aboutSection{
  /* min-width: 1083px; */
  display: flex;
  height: 168px;
  padding: 20px;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 10px;
  background: var(--Violet-Violet-900, #F2EBFF);
  margin-bottom: 20px;
}

.innerAbout{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  /* padding: 10px; */
  border-bottom:  1px solid rgba(0, 0, 0, 0.42) ;
  padding-bottom: 15px;
}
.innerAbout p{
  color: var(--text-secondary, rgba(0, 0, 0, 0.60));
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; 
  margin-bottom: 6px;
}
.aboutDesc{
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.25px;
  word-spacing: 0.5;
  text-align: justify;
  padding-right: 10px;
}

.authorCoursesList{
  width: 97.2%;

  /* min-width: 1140px; */
  /* min-height: 721px; */
  padding: 1.4%;
  /* border: 1px solid #d3d3d3; */
  border-radius: 10px;
  background-color: white;
  margin: 1.38%;
}
.authorCourses{
  position: relative;
  /* height: 49px; */
  /* margin-bottom: 20px; */
  /* min-width: 100%; */
}
.authorCourses h3{
  margin-top: 0;
  margin-bottom: 7px;
  font-size: 20px;
  line-height: 130%;
  text-align: left;
  font-weight: bold;
  font-family: "Open Sans";
  padding-left: 20px;
}
.authorCourses p{
  padding-left: 20px;
}
.courseLists{
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}





