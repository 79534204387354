@import url('../../Styles/helper.css');

@font-face {
  font-family: 'Neue Haas Display Pro';
  src: local('NeueHassDisplayBlack'),
    url(../../Fonts/NeueHaasDisplay/NeueHaasDisplayBlack.ttf),
    format('truetype');
}

.css-1ygcj2i-MuiTableCell-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #000 !important;
}

.offcanvas.offcanvas-bottom {
  max-height: 90%;
}

.tcell {
  padding: 0.88rem 0;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
}

.oppor-section {
  padding: 1.38%;
  background: #f7f7fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.oppor-section5 {
  padding: 1.38%;
  background: #f7f7fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
}

.oppor-section2 {
  padding: 1.38%;
  background: #f7f7fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
}

._2dZTy:nth-child(even) {
  fill: #f1f3f5 !important;
}

.oppor-col {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-sm {
  margin: 0.5rem;
}

.oppor-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem;
  position: relative;
}

.oppor-col h3 {
  font-size: 2rem;
}

.create-btn:focus,
.create-btn2:focus {
  border: none;
  outline: none;
}

.create-btn:hover,
.create-btn2:hover {
  background: #5a0bdf;
}

.tcell {
  color: #03071e99;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.e-gantt .e-gantt-chart .e-event-markers {
  border-left: 35px solid rgb(15 235 160 / 45%) !important;
}

.table-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.border {
  border: 1px solid #4b5255 !important;
  width: 120px !important;
  height: 40px !important;
  color: #4b5255 !important;
  text-align: center !important;
}

.arrow-down {
  position: relative;
}

.arrow-down::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #03071e;
  top: 50%;
  right: 0;
  left: 50%;
  transform: translateY(-50%);
}

thead {
  width: 100%;
  align-items: flex-end !important;
}

.NoOpec {
  opacity: 0;
}

.e-gantt .e-gantt-chart .e-connector-line {
  stroke: #04ff8a !important;
  stroke-width: 3px !important;
}

.e-gantt .e-gantt-chart .e-connectorpoint-left-hover,
.e-gantt .e-gantt-chart .e-connectorpoint-right-hover {
  display: none !important;
}
.e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
  background-color: #711fff !important;
}

.noBorder {
  border: none;
  background-color: inherit;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.noBorder:focus {
  border: none;
  outline: none;
}

.myCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.noDisplay {
  display: none;
}

.conversion {
  position: relative;
}

.popup {
  position: absolute;
  top: 7px;
  left: -171px;
  width: 200px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
}

.popupChildren {
  color: #000;
  padding: 0.8rem;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  border-radius: 7px;
  cursor: pointer;
}

.LastChild {
  border-bottom: none !important;
}

.popupChildren:hover {
  background-color: #711fff;
  color: #fff;
}

.table-data {
  width: 100%;
  border-bottom: 2px solid #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  height: 6rem;
  padding: 2rem 0;
}

.rs-calendar-table-cell::before,
.rs-calendar-table-cell::after {
  background-color: #34059a !important;
  color: rgb(255, 255, 255) !important;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  background-color: #7323fa !important;
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  border-radius: 0;
  width: 30px !important;
  height: 23px !important;
  text-align: center;
  vertical-align: center;
  padding: 0;
}

.rs-btn-primary {
  background-color: #7323fa !important;
  border-radius: 0;
}

.rs-calendar-table-cell-day::before,
.rs-calendar-table-cell-day::after {
  color: white;
}

.rs-calendar-table-cell-in-range {
  color: white;
}

.rs-calendar-table-cell-selected-start rs-calendar-table-cell-selected-end {
  background-color: #fb1f56;
}

.table-data-ps1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Neue Haas Display Pro', 'Open Sans', sans-serif;
  font-weight: 600;
  background: transparent;
}

.tableHead {
  height: 4rem;
  border-radius: 6px;
}

.AxisCenterHorizontal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  width: 560px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #8ffde7;
  padding: 2rem;
}

.video-container article h3,
.fstxtPro {
  font-weight: 700;
  font-size: 1.8rem;
  color: #000;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  padding-right: 10px;
}

.fstxt-wh {
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-family: 'Open Sans', 'Neue Haas Display Pro', sans-serif;
  font-size: 1.2rem;
}

.video-container article p {
  font-weight: 400;
  font-size: 1rem;
  color: #000;
  text-align: center;
  font-family: 'Neue Haas Display Pro', 'Open Sans', sans-serif;
}

.txtCenter {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.btnSpecial {
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  padding: 8px 18px;
  background: #711fff;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-bottom: 0.2rem;
  margin-top: 1rem;
}

.btninC:disabled {
  background: #ccc;
  cursor: not-allowed;
  border: 1px solid #000;
  pointer-events: none;
  color: #000;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.buttonGroup > button {
  width: 150px;
  height: 43px;
}

.grid-system {
  display: grid;
  grid-gap: 0.4rem;
  padding: 1rem;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow-y: auto;
}

.grid-system {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

/* WebKit (Chrome, Safari) */
.grid-system::-webkit-scrollbar {
  display: none;
}

.card-row {
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.arrow path {
  stroke-width: 5px;
}
.arrow {
  stroke-width: 5px;
}

.dataGridContainer {
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 8px;
}

.backlog {
  background-color: #e5e5e5;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.6rem 0.2rem;
  margin-bottom: 1rem;
  color: #000;
}

.inprogress {
  background: #e6f3fe;
  color: #0b88f9;
  border-radius: 8px;
  padding: 0.6rem 0.2rem;
  margin-bottom: 1rem;
  border: 1px solid #0b88f9;
}

.archive {
  background: #e6f3fe;
  color: #51b7c9;
  border-radius: 8px;
  padding: 0.6rem 0.2rem;
  margin-bottom: 1rem;
  border: 1px solid #51b7c9;
}

.brodLine {
  height: 2px;
  width: 26px;
  background-color: #000;
  margin: 0 1rem;
  color: var(--black-40);
}

.grey-txt {
  color: #8c8e8e;
  font-family: 'Open Sans';
  font-size: 1.29rem;
}

.baseTypography {
  color: #000;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 1.1rem;
  margin: 0 1rem 0 0;
}

.baseTypography2 {
  color: #000;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0 0rem 0 0;
}

.greybtn {
  background-color: #f8f9fa;
  display: flex;
  width: 47%;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  color: #711fff;
  font-family: 'Open Sans';
  align-items: center;
  font-weight: 500;
  border: none;
  font-size: 1rem;
  outline: none;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}

.state {
  color: #4b5255;
  font-size: 0.9rem;
  margin: 0 !important;
  text-transform: uppercase;
}

.needs_more_work {
  background: #fff0e9;
  color: #ff6827;
  border: 1px solid #ff6827;
  border-radius: 8px;
  padding: 0.6rem 0.2rem;
  margin-bottom: 1rem;
}

.stuck {
  background: #feeae9;
  color: #fa2d23;
  border: 1px solid #fa2d23;
  border-radius: 8px;
  padding: 0.6rem 0.2rem;
  margin-bottom: 1rem;
}

.rowText {
  color: var(--black-80);
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 1rem;
}

.Netdanger {
  color: #fa2d23;
  font-weight: 500;
  font-family: 'Open Sans';
  transition: all 0.1s ease-in-out;
  font-size: 0.9rem;
}

.netWarn-btn {
  color: #f75656;
  border: 1.5px solid #f75656;
  font-weight: 500;
  font-family: 'Open Sans';
  transition: all 0.1s ease-in-out;
  font-size: 0.9rem;
  padding: 0.48rem 1.3rem;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.netWarn-btn:hover {
  background-color: #f75656;
  color: #fff;
}

.netDanger-btn {
  color: #fa2d23;
  border: 1.5px solid #fa2d23;
  font-weight: 500;
  font-family: 'Open Sans';
  transition: all 0.1s ease-in-out;
  font-size: 0.9rem;
  padding: 0.48rem 1.3rem;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.netDanger-btn:hover {
  background-color: #fa2d23;
  color: #fff;
}

.Netdanger:hover {
  color: red;
}

.review {
  background: #f1e8ff;
  color: #711fff;
  border: 1px solid #711fff;
  border-radius: 8px;
  padding: 0.6rem 0.2rem;
  margin-bottom: 1rem;
}

.ex-sm-txt {
  font-size: 0.9rem;
  font-weight: 600;
  font-family: 'Open Sans';
  text-transform: uppercase;
  color: var(--black-40);
}

.completed {
  background: #effcf4;
  color: #5fcea8;
  border: 1px solid #5fcea8;
  border-radius: 8px;
  padding: 0.6rem 0.2rem;
  margin-bottom: 1rem;
}

.greenTxT {
  color: #5fcea8;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Open Sans';
  text-align: left;
}

.review,
.completed,
.stuck,
.needs_more_work,
.inprogress,
.archive,
.backlog {
  font-size: 13px;
  transition: all 0.2s ease-in-out;
  margin: 0 !important;
  border-radius: 5px;
  padding: 5px 1px;
}

.hover_button:hover {
  background-color: #711fff !important;
  color: #fff !important;
  transition: background-color 0.3s ease;
}

.review,
.completed,
.stuck,
.needs_more_work,
.inprogress,
.archive,
.backlog:focus {
  outline: none;
}

.myWatch {
  font-size: 2rem;
  font-weight: 500;
  color: #000;
}

.stepIndicator {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.82rem;
}

.newChipex {
  display: flex;
  min-width: 100px;
  max-width: auto;
  padding: 0.23rem 0.76rem;
  border: 1px solid #711fff;
  color: #711fff;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-weight: 500;
  background: #f2ebff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.newChipex:hover {
  color: #fff;
  font-weight: 600;
  background-color: #711fff;
}

.estaBtn {
  border: 0.2px solid #adadad;
  outline: none;
  border-radius: 4px;
  padding: 0.45rem 2rem;
  border-radius: 8px;
  background: #fff;
  font-family: 'Open Sans';
  width: 185px;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 1.15rem;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.slctd {
  background-color: #711fff;
  color: #fff;
  font-weight: 500;
}

.css-1ald77x.Mui-error {
  color: gray !important;
}

.css-slyssw {
  width: 100% !important;
  height: 60px !important;
  border-radius: 0px !important;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #711fff !important;
}

.fstxt3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
}

.container-psU-p5 {
  padding: 2rem 1.5rem !important;
  background-color: #fff;
  border-radius: 8px;
  height: 750px;
  width: 100%;
  overflow-y: auto;
}

.currentStep {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  background-color: #711fff;
  color: #fff;
  padding: 0.3rem 1rem;
  border-radius: 8px;
  margin-bottom: 0 !important;
}

.PrevStep {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: var(--black-80);
  margin-bottom: 0 !important;
}

.btnRound {
  background-color: #f6f6f6;
  border-radius: 8px;
  border: none;
  outline: none;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  color: #000;
  padding: 13px 65px;
}

.smallChipex {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(3, 7, 30, 0.6);
  background: #e9e9ea;
  border-radius: 4px;
  padding: 0.23rem 0.69rem;
}

.lgChipex {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(3, 7, 30, 0.6);
  background: #e9e9ea;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  transition: all 0.1s ease-in-out;
  width: 200px;
  cursor: pointer;
}

.lgChipex-active {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
  background: #a16bff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 200px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.MuiStepConnector-root {
  width: 50% !important;
  margin: 0 auto !important;
}

@media (min-width: 1024px) {
  .MuiStepConnector-root {
    width: 45% !important;
  }
}

@media (min-width: 1400px) {
  .MuiStepConnector-root {
    width: 55% !important;
  }
}

.css-8t49rw-MuiStepConnector-line {
  display: block !important;
  border-color: #292828 !important;
  border-left-style: solid !important;
  border-left-width: 1.5px !important;
  min-height: 16px !important;
}

.css-117w1su-MuiStepIcon-text {
  fill: rgb(0, 0, 0) !important;
  font-size: 0.75rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #ffffff !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #ffffff !important;
}

.css-u8iggh-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}

.css-ypp7sj-MuiAvatar-root {
  font-size: 18.5px !important;
}

.autoWidth {
  width: 100% !important;
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1400px) {
  .det-btn {
    font-size: 0.9rem !important;
    padding: 0.2rem 0.4rem;
  }
}

@media only screen and (max-width: 1201px) {
  .autoWidth {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

.firstMenu {
  width: 11.5%;
  left: 10px;
}

.secondMenu {
  left: 12.5%;
}

@media only screen and (max-width: 1560px) {
  .firstMenu {
    width: 14%;
    left: 10px;
  }
  .secondMenu {
    left: 15%;
  }
}

@media only screen and (max-width: 1310px) {
  .firstMenu {
    width: 14%;
    left: 5px;
  }
  .secondMenu {
    left: 15%;
  }
}

@media only screen and (max-width: 1200px) {
  .firstMenu {
    width: 20%;
    left: 40px;
  }
  .secondMenu {
    left: -0.2%;
  }
}

@media only screen and (max-width: 900px) {
  .firstMenu {
    width: 20%;
    left: 40px;
  }
  .secondMenu {
    left: -0.9%;
  }
}
