/* ----------------------
COLORS -----------------*/
:root {
    --white-100: #FFFFFF;
    --white-90: #ffffffe6;
    --white-80: #ffffffcc;
    --white-60: #ffffff99;
    --white-40: #ffffff66;
    --white-20: #ffffff33;
    --black-100: #03071E;
    --black-80: #03071ecc;
    --black-60: #03071e99;
    --black-50: #03071e80;
    --black-40: #03071e66;
    --black-20: #03071e33;
    --black-10: #03071e1a;
    --grey-100: #F8F9FA;
    --grey-200: #E9ECEF;
    --grey-300: #f5f5f5;
    --grey-400: #E9ECEF;
    --grey-700: #E0E0DF;
    --grey-800: #E9ECEF;
    --grey-900: #F1F3F5;
    --dark-grey: #4B5255;
    --placeholder: #A3A9AB;
    --teal-100: #001A14;
    --teal-200: #004D3D;
    --teal-300: #008066;
    --teal-400: #00B38F;
    --teal-500: #00E6B8;
    --teal-600: #1AFFD1;
    --teal-700: #4CFFDB;
    --teal-800: #80FFE6;
    --teal-900: #B3FFEF;
    --teal-1000: #E6FFFA;
    --violet: #711FFF;
    --violet-100: #1E0052;
    --violet-200: #310085;
    --violet-300: #4300B8;
    --violet-400: #5600EB;
    --violet-500: #711FFF;
    --violet-600: #9152FF;
    --violet-700: #B185FF;
    --violet-800: #D2B8FF;
    --violet-900: #F2EBFF;
    --pink-500: #FF33BB;
    --pink-600: #FF56C6;
    --pink-800: #FABDE5;
    --blue-500: #1FAEFF;
    --blue-600: #5AC4FF;
    --blue-800: #BAE6FF;
    --success-400: #47B838;
    --success-500: #5AD34A;
    --danger-300: #AF9300;
    --danger-400: #D8B604;
    --danger-500: #F1D126;
    --error-400: #CE2452;
    --error-500: #EE3164;
    --error-700: #F25F86;
    --base-font-size: 15px;
    font-size: var(--base-font-size);
}

/* GLOBAL SETTING */
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

/* a{
text-decoration: none;
} */

@media (max-width : 1400px) {
    :root {
        --base-font-size: 13px !important;
    }
}