.richEditor .MUIRichTextEditor-placeHolder-65{
    top: 0 !important;
    left: 0 !important;
    display: none !important;
}
.richEditor .MUIRichTextEditor-toolbar-55{
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex !important;
}

#mui-rte-toolbar{
    text-align: end !important;
}
#mui-rte-container{
    display: flex;
    flex-direction: column-reverse;  
}

.my-row .row{
    height: 100% !important;
}
.previewContainer{
    max-width: 100%;
    display: flex;
    padding: 20px 40px;
    align-items: flex-start;
    gap: 10px;
    background: var(--Frame-Background, #F7F7FA);
}

.innerContainer{
    display: flex;
    padding: var(--none, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: 15px !important;
    background: var(--Frame-Background, #F7F7FA);
    justify-content: space-between;
    width: 100%;
}
.formSpan2{
    width: 38% !important;
}
.sideFormSpan2{
    justify-content: start !important;
}
.logoSideContainer{
    display: flex;
    width: 100%;
    padding: var(--none, 0px);
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
}
.logoSide{
    display: flex;
    
    padding: var(--none, 0px);
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.logoSide img{
    display: flex;
    padding: 11px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 60px;
    background: var(--Violet-Violet-600, #9152FF);
}

.teamInfo .team{
    color: var(--Black-Black-100, #03071E);
    text-align: right;
    font-family:'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
}
.times{
    color: var(--Black-Black-40, rgba(3, 7, 30, 0.40));
    text-align: start;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
}
.titleSpace{
    color: var(--Black-Black-80, rgba(3, 7, 30, 0.80));
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    margin-top: 15px;
}

.contentSpace{
    color: var(--Black-Black-80, rgba(3, 7, 30, 0.80));
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-top: 15px;
}

/* preview */

.previewLeftSide{
    width: 100%;
    min-height: 484px;
    display: flex;
    padding: var(--none, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 24px);
    flex: 1 0 0;
}

.previewPara{
    color: var(--Black-Black-100, #03071E);
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    width: 100%;
}


.col-system {
    width: 97.2%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 1rem;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none
}
.top-title{
    display: flex;
    width: 100%;
    gap: 3%;
}

.left_side .name, .right_side .name{
    width: 100% !important;
}
.name .sideFormSpan{
    width: 100%;
}
.richEditor{
    display: flex;
    margin-top: 20px;
    gap: 8%;
    width: 100%;
}
.summeryMobile{
    display: flex;
    gap: 10%;
}
@media (max-width: 1300px){
 
    .top-title{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8%;
    }
    .richEditor{
        flex-direction: column;
        gap: 8%;
    }
    .richEditor .name{
        display: none;
    }
    .summeryMobile{
        flex-direction: column;
    }.previewRight{
        margin-bottom: 40px;
    }
    }

    @media(max-width: 992px){
        .top-title .left_side, .top-title .right_side{
            width: 80% !important;
        }

    }

    /* preview */

    .formSpan5{
        width: 200px !important;
    }
    .name5{
        width: 100% !important;
    }