
/* stylelint-disable  */

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  overflow: auto;
}
/* stylelint-enable  */