.sub-title {
  position: relative;
  padding-top: -1.37%;
  padding-bottom: -1.37%;
}

/* stylelint-disable  */
.alCenter {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 2rem;
}

.cancel-btn {
  margin-right: 2rem !important;
}

.m4-left {
  margin-left: 2rem;
}

.m4-right {
  margin-right: 2rem;
}

.Center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.col-md-6 {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
  width: 400px !important;
}

.specialTxT {
  font-size: 1rem;
  color: #711fff;
  font-weight: 600;
  margin-left: 4px;
}

.noPad {
  padding: 0 !important;
  margin: 0 !important;
}

.tabContent {
  width: 100%;
}

.newBtn {
  background-color: #711fff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin: 0.5rem 0;
  width: 120px;
  height: 60px;
}

.tag {
  background-color: #a16bff !important;
  color: #fff !important;
  width: auto !important;
  padding: 0 0.3rem !important;
  border-radius: 4px !important;
}

.btnGroups {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 4.93%;
  width: 100%;
}

.card {
  background: #1e0052;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 1.8rem;
  gap: 0.8rem;
}

.card2 {
  background: #e0e0e5;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 1.8rem;
  gap: 0.8rem;
}

.Mega-Grid-Container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2rem;
  width: 100%;
  height: 700px;
  gap: 1rem;
  overflow-y: auto;
  background-color: var(--grey-300);
  border-radius: 8px;
  mask-image: linear-gradient(
    0deg,
    rgba(233, 236, 239, 0) 0%,
    var(--grey-300) 40.95%
  );
  mask-size: 40% 100%;
  padding: 3rem 0;
}

.column-system {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.5;
}

.gs-10 {
  border-radius: 8px;
  width: 100%;
}

.colgs-10p90q {
  width: 100%;
}

/* overide Mui TextField Padding */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.MuiAutocomplete-input,
.css-1x5jdmq {
  padding: 13.5px 11px !important;
}

.css-jc972a-MuiAutocomplete-root {
  padding: 0 0 !important;
}

.css-jc972a-MuiAutocomplete-root,
.MuiOutlinedInput-root {
  padding: 1.5px !important;
}

/*  End */

.det-btn {
  background-color: #711fff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.2rem 0.75rem;
  font-size: 1rem;
  font-weight: 600;
}

.Tab-System {
  background-color: #fff !important;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 50%;
}

.det {
  background-color: #fff !important;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  flex: 0.8;
}

.auto {
  height: auto !important;
}

.cardTxt {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.4rem;
  font-family: 'Open Sans', sans-serif;
  margin-left: 1rem;
}

.cardTxt2 {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 0.4rem;
  font-family: 'Open Sans', sans-serif;
  margin-left: 1rem;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  gap: 2rem;
}

.FileCheck {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  gap: 5rem;
  margin-top: 1.5rem;
}

.uploadBox {
  display: flex;
  flex-direction: column;
  background: hsla(231, 82%, 6%, 0.1);
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 1rem;
  border-radius: 8px;
}

.css-1nitb7c {
  background: #f2f2f2 !important;
}

.css-opb0c2 {
  background: #fff !important;
}

.white-box {
  background-color: #f5f5f5;
  width: 90px;
  height: 100px;
  border-radius: 4px;
}

/* ----------------------------------- */
.css-1kwdphh-MuiDataGrid-virtualScrollerContent {
  background-color: rgb(255, 255, 255) !important;
}

/* controls the mui footer , for pagination */
.MuiDataGrid-footerContainer {
  display: none !important;
}

/* ---------------------- */

/* MUI GRID OVERRIDE */
.css-11q23vl-MuiGrid-root > .MuiGrid-item {
  padding-left: 0 !important;
}

.css-1d9u8pk > .MuiGrid-item {
  padding-left: 0 !important;
}

/* -------------------------------- */
.grey-box {
  background-color: #c4c4c4;
  width: 60px;
  height: 60px;
  margin-top: 0.5rem;
}

.btnSm {
  width: 100% !important;
}

.smBtn {
  background-color: #711fff;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;

  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.btninCc {
  background-color: #711fff;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.smBtn:disabled {
  background-color: #c4c4c4;
  color: #000;
  border: none;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: not-allowed;
  width: 120px;
  height: 40px;
}

.txtSM {
  margin-top: 1.5rem;
  font-size: 1rem !important;
}

.txtESM {
  font-size: 15px !important;
  width: 55%;
  color: #000;
  margin-top: 0.5rem;
  margin-bottom: 1rem !important;
}

.AlLeft {
  text-align: left !important;
  justify-content: center !important;
  align-items: flex-start !important;
  align-self: flex-start !important;
  margin-left: 1.5rem;
}

.AlLeft h3 {
  font-size: 1.5rem !important;
  font-weight: 600;
  color: #000;
  margin-bottom: 1rem;
}

.AlRight {
  text-align: right !important;
  align-self: flex-end !important;
  margin-right: 2.5rem;
}

.p-1 {
  padding: 2rem;
}

.file-input {
  width: 600px !important;
  height: 196px !important;
  background: hsla(0, 0%, 96%, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #711fff;
  border-radius: 8px;
}

.bd600 {
  font-weight: 600;
}

.footer {
  margin-top: auto;
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 4px 34px 0 hsl(0deg 0% 0% / 25%);
  border-radius: 8px;
}

.footer-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0 !important;
  border-radius: 8px;
}

.footer-list li {
  list-style: none;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  border-radius: 8px;
  padding: 0.87rem 1.3rem;
  min-width: 90px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.thisStep {
  background-color: hsl(168deg 100% 80% / 100%);
  font-weight: 800 !important;
}

.fullHight {
  height: 100% !important;
}

.coloumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.table-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.greyTag {
  background-color: #c4c4c4;
  min-width: 84px;
  padding: 0.44rem 0.88rem;
  border-radius: 4px;
  height: 38px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  position: relative;
  font-size: 0.9rem;
  color: #000;
  gap: 0.5rem;
  display: flex;
  align-items: baseline;
}

.cross {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #7a7a7a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.grey-bg {
  background-color: #e9ecef !important;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.emptySpace {
  height: 200px;
  width: 100%;
  pointer-events: none;
  opacity: 0;
}

.Tab-Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  gap: 2rem;
}

.notes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.84rem 0.45rem;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  gap: 2rem;
  height: 100%;
}

.notes > article {
  width: 100%;
  justify-content: space-between;
  align-self: flex-end;
}

.notes > article > input {
  width: 70%;
  background-color: #f1f3f5 !important;
  padding: 0.5rem;
  border: none;
}

.notes > article > input:focus {
  outline: 1px solid #711fff;
}

.alLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.3rem;
}

.date {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(3, 7, 30, 0.4);
}

.txtNRM {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #000;
  margin-left: 1rem;
}

.searching {
  /* hightlight text */
  background-color: var(--danger-500);
}

.col-NP-NC {
  display: flex !important;
  flex-direction: column !important;
  gap: 0.4rem !important;
  padding-bottom: 1rem;
  overflow-y: auto !important;
}

.input-groups {
  align-items: center !important;
}

.projectDrop {
  max-width: 225px;
  width: 100%;
  height: 132px;
  background-color: var(--white-100);
  border: 1px solid var(--black-20);
  border-radius: 8px;
  padding: 10px 0px;
  position: absolute;
  bottom: 0;
  z-index: 99;
}

.NoteMsg {
  padding: 0.7rem 0rem;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.notes-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  overflow-y: auto;
  padding: 0 !important;
}

.OporMenu {
  max-width: 225px;
  width: 100%;
  height: 132px;
  background-color: var(--white-100);
  border: 1px solid var(--black-20);
  border-radius: 8px;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  z-index: 99;
  right: 13px;
}

.txtL {
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  font-family: 'Neue Haas Display Pro', sans-serif;
}

.df-r-j-c-gp-5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  padding-bottom: 5px !important;
}

.df-r-j-c-gp-15 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}

.myJam {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.df-r-j-c-gp-5 > span {
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(3, 7, 30, 0.8);
  font-family: 'Open Sans', sans-serif;
}

.Searchfilter {
  position: absolute;
  border-radius: 8px;
  border: 1px solid #ccc;
  outline: none;
  font-family: 'Neue Haas Display Pro', sans-serif;
  padding: 0.54rem;
  left: 95px;
}

.Searchfilter:focus {
  outline: 2px solid #711fff;
  border: none;
}

.Searchfilter::placeholder {
  font-family: 'Neue Haas Display Pro', sans-serif;
  font-size: 1rem;
  color: #ccc;
}

.OppCreate {
  border: none;
  outline: none;
  padding: 1rem;
  border-radius: 4px;
}

.OppCreate:focus {
  outline: 2px solid #711fff !important;
}

.sortDrop {
  max-width: 135px;
  width: 100%;
  height: 132px;
  background-color: var(--white-100);
  border: 1px solid var(--black-20);
  border-radius: 8px;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  z-index: 99;
  left: 100px;
}

.sortDrop {
  max-width: 135px;
  width: 100%;
  height: 132px;
  background-color: var(--white-100);
  border: 1px solid var(--black-20);
  border-radius: 8px;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  z-index: 99;
  left: 100px;
}

.noMargin {
  margin-top: 1rem;
}

.hp500qqz {
  height: 400px;
}

.pad-0 {
  padding: 0.2rem !important;
  width: 200px !important;
  margin: auto !important;
  border: none !important;
}

.td {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: var(--black-100);
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hoverLabel {
  font-size: 14px;
  color: black;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  padding: 12px 0;
  transition: background-color 0.3s ease;
  cursor: pointer;
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hoverLabel:hover {
  background-color: #f2ebff;
}

.labelClick {
  background-color: #711fff;
  color: white;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  font-weight: 600;
}

.labelClick:hover {
  background-color: #711fff;
}

.cardIn {
  transition-duration: 0.15s;
  border-radius: 6px;
}

.cardIn:focus {
  outline: #711fff;
  transition-duration: 0.15s;
}

.payBtn {
  color: white;
  background: #711fff;
}

.payBtns:hover {
  background-color: #711fff;
  color: white;
}

.payBtns {
  background: white;
  color: #711fff;
  border: 1px solid #711fff;
  border-width: 1px;
}

.tableHeader {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: var(--black-60);
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mui-In-CC {
  background-color: inherit;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px dashed #ccc;
  font-size: 1rem;
}

.css-11yukd5-MuiTabs-indicator {
  display: none;
}

.MUI-box-in-CC {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  border-bottom: 1px solid var(--black-10);
  padding: 0.6rem 0;
  width: 400px;
  height: 70px;
}

.StopWatch-display {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  background: #b2ffef;
  padding: 0.44rem 0.88rem;
  border-radius: 4px;
}

.MUI-box-in-qt {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  padding: 0.6rem 0;
  justify-content: space-between;
  width: 600px;
  height: 70px;
}

.MUI-box-in-CC > span {
  color: var(--black-40);
  width: 120px;
}

.employeeTab {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 189px;
  background: #f5f5f5;
  height: 58px;
  color: var(--black-100);
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  padding: 0 0.8rem;
}

.fullTab {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  background: #f5f5f5;
  height: 88px;
  color: var(--black-100);
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  padding: 0 0.8rem;
}

.fullTabV2 {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  height: 68px;
  color: var(--black-100);
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  padding: 0 0.8rem;
  border: 1px solid #d9d9d9;
  box-shadow: 1px 1px hsla(231, 82%, 6%, 0.1);
}

.chiplet {
  background: #e6e7e9;
  height: 48px;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.75rem;
  border-radius: 4px;
}

.chipletActive {
  background-color: #711fff;
  color: #fff;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0.4rem 0.75rem;
  border-radius: 4px;
}

.ltl-btn {
  background-color: #a16bff;
  color: #fff;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 10%;
  margin-left: auto;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.company-name {
  color: #0b88f9;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
  margin: 0 !important;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.MUI-OUT-CC {
  align-items: center !important;
}

.dd-import-group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media only screen and (width <= 1300px) {
  .myJam {
    width: 100%;
  }

  .OppCreate {
    width: 500px;
  }

  .upload-content {
    gap: 0.2rem;
  }

  .modalSpan {
    width: 130px;
  }
}

@media only screen and (max-width: 1000px) {
  .OppCreate {
    width: 400px;
  }
}

@media only screen and (max-width: 800px) {
  .OppCreate {
    width: 200px;
  }

  .row {
    flex-direction: column !important;
  }

  .col-NP-NC {
    min-height: max-content !important;
    overflow: hidden hidden !important;
  }

  .Mega-Grid-Container {
    overflow: auto hidden !important;
    height: 500px !important;
  }

  .MegaGridContainer {
    height: 500px;
    overflow: hidden auto;
  }

  .df-r-j-c-gp-5 {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .df-r-j-c-gp-5 > span {
    display: none;
  }

  .Center {
    margin: 2rem 0;
  }
}

@media only screen and (width <= 400px) {
  .df-r-j-c-gp-5 {
    width: 170px;
  }

  .det-btn {
    display: none;
  }

  .grid-system {
    padding: 0.4rem 0.5rem;
  }
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: gray !important;
}

/* MUI FIELDS DANGER ZONE */
.css-ex1ihn {
  width: 100% !important;
}
