/* stylelint-disable */

.css-r8u8y9 {
  padding: 0 !important;
}

.MuiBox-root .css-ve28d2 {
  flex-wrap: wrap;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}

.select-dropdown {
  font-weight: 500;
  text-align: center;
  max-width: 250px;
  min-width: 150px;
}

.select-dropdown .MuiSelect-select:focus,
.select-dropdown .MuiSelect-select:hover {
  background-color: var(--primary-color);
  color: #fff;
  outline: none !important;
  border: none !important;
}

.select-dropdown .MuiSelect-select {
  background-color: var(--primary-color);
  color: #fff;
  padding: 6px 30px 8px 10px; /* Default for medium screens */
}

.select-dropdown .MuiSelect-select:hover,
.select-dropdown .MuiSelect-select:focus {
  background-color: var(--dark-primary-color);
}

@media (min-width: 768px) {
  .SelectDropdown .MuiSelect-select {
    padding: 7px 32px 10px 10px; /* Large screens */
  }
}

@media (min-width: 1200px) {
  .select-dropdown .MuiSelect-select {
    padding: 9px 32px 10px 10px; /* Extra-large screens */
  }
}

.select-dropdown .MuiSelect-icon {
  color: #fff;
}

.select-dropdown .MuiSelect-selectMenu {
  color: #000;
}

.tick-icon {
  height: 18px;
  width: 18px;
  margin-left: -5px;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .first-filter .MuiSelect-select {
    padding: 7px 32px 10px 10px; /* Large screens */
  }
}

@media (min-width: 1200px) {
  .first-filter .MuiSelect-select {
    padding: 9px 32px 10px 10px; /* Extra-large screens */
  }
}

.first-filter .MuiSelect-icon {
  color: #fff;
}

.first-filter .MuiSelect-selectMenu {
  color: #fff;
}

.filter-option {
  font-weight: 500;
  margin-top: 5px;
  background-color: #fff;
  color: #000;
}

.filter-option:hover {
  background-color: #d2b8ff;
  color: #000;
}

.filter-option.Mui-selected {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.team-menu-item {
  padding: 0 1rem 0 1rem;
  width: 100%;
  font-weight: 600 !important;
}

.team-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.team-title {
  white-space: nowrap;
}

.select-all-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding-left: 1.8rem;
}

.member-box {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.groupby-table-header-cell {
  font-size: 1.1rem !important;
  padding: 0 !important;
  padding-left: 10px !important;
  color: gray !important;
  font-family: 'Open Sans', sans-serif !important;
  white-space: nowrap !important; /* Prevents text wrapping */
  overflow: hidden !important; /* Hides overflowing content */
  text-overflow: ellipsis !important; /* Adds ellipsis for overflowing text */
  background-color: #fff;
}

.groupby-header-text {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  margin: 1rem;
  margin-top: 2rem;
  color: #000;
  gap: 0.5rem;
}

.task-cell {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-left: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-header {
  display: flex;
  height: 80px;
  position: relative;
  cursor: pointer;
}

.project-name {
  color: var(--primary-color);
  position: absolute;
  top: 50px;
  left: 0px;
  font-size: 13px;
}

.assignee-details {
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Open Sans';
  overflow: hidden;
}

.no-data-text {
  color: black;
  height: 90%;
  display: flex;
  background: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.confirm-delete-input {
  width: 100%;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #000000;
  text-align: center;
  border: none;
  border-bottom: 1px dashed #000000;
}

.confirm-delete-input:focus {
  outline: none;
  border-bottom: 1px dashed #000000;
}

.confirm-delete-input:hover {
  border-bottom: 1px dashed #000000;
}

.confirm-delete-input::placeholder {
  color: #aaa;
}

.main-background-div-delete-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.container {
  width: 850px;
  height: 320px;
  background: rgba(241, 243, 245, 0.5);
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Inner Box Styles */
.inner-box {
  width: 95%;
  height: 95%;
  background-color: rgba(255, 255, 255);
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 0.68rem 1rem;
  justify-content: space-around;
  box-shadow: 2px 2px 6px 0px #d9d9d9;
  gap: 10px;
}

/* Delete Text Styles */
.delete-text {
  font-size: 1.17rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #cd0b4c;
}

/* Description Text */
.description-text {
  width: 80%;
  text-align: center;
  font-weight: 400;
}

/* Confirm Section */
.confirm-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instruction-text {
  font-weight: 400;
}

.input-wrapper {
  width: 40%;
  border-radius: 8px;
  padding: 1rem 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
}

/* Input Field Styles */
.confirm-delete-input {
  width: 100%;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #000000;
  text-align: center;
  border: none;
  border-bottom: 1px dashed #000000;
}

.confirm-delete-input:focus {
  outline: none;
  border-bottom: 1px dashed #000000;
}

.confirm-delete-input::placeholder {
  color: #000000;
  opacity: 0.6;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 0 auto;
}

.delete-button {
  font-family: 'Neue Haas Display Pro', 'Open Sans', sans-serif;
  font-weight: 500;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  padding: 0rem 2rem;
  height: 38px;
  background-color: var(--button-default);
  transition: all 0.2s ease-in-out;
}

.delete-button[disabled] {
  background-color: #f99f9f;
  cursor: not-allowed;
}

.delete-button:not([disabled]) {
  background-color: #cd0b4c;
}

.delete-button:hover:not([disabled]) {
  background-color: #b00942;
}
